import React, { useEffect, useState, useRef } from "react";
import InstituteTemplate from "../../../Components/InstituteTemplate/InstituteTemplate";
import ClientInfo from "../CourseProgress (Institute)/Components/ClientInfo";
import CourseInstructors from "./Components/CourseInstructors/CourseInstructors";
import StudentTimeTable from "./Components/StudentTimeTable/StudentTimeTable";
import InstructorSlider, {
  SchedulerCompleted,
} from "./Components/ManageStudentCarousel/ManageStudentCarousel";
import { useDispatch, useSelector } from "react-redux";
import { CreateScheduleA, GetClassScheduleA, GetUserByIdA } from "../../../Actions/StudentA";
import { useParams } from "react-router-dom";
import { GetAboutInfoA } from "../../../Actions/CourseA";
import useCheckLogin from "../../../Helpers/CustomHooks/CheckLogin";
import StudentScheduleTimeTable from "./Components/StudentPreferreTimeTable/StudentPreferreTimeTable";
import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";
import MyCourses from "../../../Components/MyCourses/MyCourses";
import { InsEnrolledCourseButtons } from "../../../PageNames";
import "./Components/CourseInstructors/courseI.css";
import { useAuthContext } from "../../../context/authContext";
import { GetLocalStorage } from "../../../Helpers/LocalStorage/LocalStorage";
import { RRule, RRuleSet, rrulestr } from "rrule";
import LastLicense from "../CourseProgress (Institute)/Components/LastLicense";
import LicenseFullView from "./Components/CourseInstructors/Components/LicenseFullView";
import { BaseUrl } from "../../../Actions/Base";
import LicenseUpdatePopup from "./Components/CourseInstructors/Components/LicenseUpdatePopup";
import TabsSection from "./Components/CourseInstructors/Components/TabsSection";
import { useLocation } from 'react-router-dom';


function ManageNewStudentChild() {
  const location = useLocation(); // Use useLocation to get the state
  const { userId } = location.state || {}; // Destructure userId from the state

  console.log("User ID in manage:", userId); // Use the userId here

  const { lastFiveValues } = useAuthContext();
  const Dispatch = useDispatch();
  const { EnrollmentId } = useParams();
  const { Student, ScheduleTimeTable, loading, CoursePackage } = useSelector(
    (Store) => Store.StudentReducer
  );
  console.log("Student", Student);
  // console.log("ScheduleTimeTable", ScheduleTimeTable)
  const [Events, setEvents] = useState([]);
  const [licenseFullView, setLicenseFullView] = useState(false);
  const [licenseUpdate, setLicenseUpdate] = useState(false);
  const imageUrl = `${BaseUrl}/api/Images/LatestLicense?url=${Student?.StudentInfo?.LatestLicense}`;
  const [StudentPrefrredTimeTable, setStudentPrefrredTimeTable] = useState([]);
  const [StudentEnrollmentSelectedTimeTable, setStudentEnrollmentSelectedTimeTable] = useState([]);

  const [OneInstructor, setOneInstructor] = useState();
  const [ShowSlider, setShowSlider] = useState({
    InstructorType: "",
    Show: false,
  });
  console.log("CoursePackage", CoursePackage);
  const [Instructors, setInstructors] = useState([
    undefined,
    undefined,
    undefined,
  ]);
  const ClassType = [
    { id: "Driving", text: "Driving" },
    { id: "Online", text: "Online" },
    { id: "InClass", text: "InClass" },
  ];

  const [activeTab, setActiveTab] = useState('client');

  const clientRef = useRef(null);
  const instructorsRef = useRef(null);
  const timetableRef = useRef(null);

  // const handleScroll = () => {
  //   console.log("handleScroll called");
  //   if (!clientRef.current || !instructorsRef.current || !timetableRef.current) {
  //     return;
  //   }

  //   const clientTop = clientRef.current.getBoundingClientRect().top;
  //   const instructorsTop = instructorsRef.current.getBoundingClientRect().top;
  //   const timetableTop = timetableRef.current.getBoundingClientRect().top;

  //   if (clientTop <= 0 && instructorsTop > 0) {
  //     setActiveTab('client');
  //   } else if (instructorsTop <= 0 && timetableTop > 0) {
  //     setActiveTab('instructors');
  //   } else if (timetableTop <= 0) {
  //     setActiveTab('timetable');
  //   }
  // };

  // useEffect(() => {
  //   console.log("Attaching scroll event listener");
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     console.log("Removing scroll event listener");
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // const scrollToSection=()=> {};

  // const scrollToSection = (section) => {
  //   const refMap = {
  //     client: clientRef,
  //     instructors: instructorsRef,
  //     timetable: timetableRef,
  //   };
  //  //Scroll smoothly to the selected section
  //   refMap[section].current.scrollIntoView({ behavior: 'smooth' });
  //   setActiveTab(section); // Set the active tab after scrolling
  // };

  const scrollToSection = (section) => {
    const refMap = {
      client: clientRef,
      instructors: instructorsRef,
      timetable: timetableRef,
    };
  
    const sectionRef = refMap[section]?.current;
  
    if (sectionRef) {
      setActiveTab(section); // Set the active tab first
      setTimeout(() => {
        sectionRef.scrollIntoView({ behavior: 'smooth' });
      }, 100); // Adjust the timeout as needed
    }
  };

  useEffect(() => {
    if (EnrollmentId) {
        Dispatch(GetClassScheduleA(EnrollmentId)); 
        Dispatch(GetAboutInfoA(EnrollmentId)); 
    }
  }, [Dispatch, EnrollmentId, userId]); // Add `userId` if it's used in the effect


  useCheckLogin(true, ["Institute"], ["Staff", "Admin"]);

  // useEffect(() => {
  //   if (ScheduleTimeTable) {
  //     const EditAble = JSON.parse(JSON.stringify(ScheduleTimeTable));
  //     setStudentPrefrredTimeTable([
  //       ...EditAble.map((value) => {
  //         value.startDate = new Date(value.start);
  //         value.endDate = new Date(value.end);
  //         value.allDay = false;
  //         value.description = "";
  //         value.text = "";
  //         return value;
  //       }),
  //     ]);
  //   }
  // }, [ScheduleTimeTable]);

  useEffect(() => {
    if (ScheduleTimeTable) {
      console.log("schedule time table is:", ScheduleTimeTable);
      const editAble = JSON.parse(JSON.stringify(ScheduleTimeTable));

      // Convert schedule times to events considering full-hour intervals
      const convertedEvents = editAble.map((value) => {
          let startDate = new Date(value.start);
          let endDate = new Date(value.end);

          // Set the start and end times to the nearest full hour
          startDate.setMinutes(0, 0, 0); // Start time rounded to the start of the hour
          endDate.setMinutes(0, 0, 0); // End time rounded to the end of the hour

          // Adjust the end time if the duration is less than an hour
          if (endDate.getTime() - startDate.getTime() < 60 * 60 * 1000) {
              endDate.setHours(startDate.getHours() + 1); // Ensure event ends exactly after 1 hour
          }

          return {
              ...value,
              startDate,
              endDate,
              allDay: false,
              description: "",
              text: "",
          };
      });

      console.log("converted events are:", convertedEvents);
      setStudentPrefrredTimeTable(convertedEvents);
    }
}, [ScheduleTimeTable]);

useEffect(() => {
  if (ScheduleTimeTable) {
    console.log("schedule time table is:", ScheduleTimeTable);
    const editAble = JSON.parse(JSON.stringify(ScheduleTimeTable));

    // Convert schedule times to events considering full-hour intervals
    const convertedEvents = editAble.map((value) => {
        let startDate = new Date(value.start);
        let endDate = new Date(value.end);

        // Set the start and end times to the nearest full hour
        startDate.setMinutes(0, 0, 0); // Start time rounded to the start of the hour
        endDate.setMinutes(0, 0, 0); // End time rounded to the end of the hour

        // Adjust the end time if the duration is less than an hour
        if (endDate.getTime() - startDate.getTime() < 60 * 60 * 1000) {
            endDate.setHours(startDate.getHours() + 1); // Ensure event ends exactly after 1 hour
        }

        return {
            ...value,
            startDate,
            endDate,
            allDay: false,
            description: "",
            text: "",
        };
    });

    console.log("converted events are:", convertedEvents);
    setStudentEnrollmentSelectedTimeTable(convertedEvents);
  }
}, []);



  
  return (
    <>
      {!loading ? (
        <>
          {licenseFullView && (
            <LicenseFullView
              imageUrl={imageUrl}
              setLicenseFullView={setLicenseFullView}
            />
          )}
          {licenseUpdate && (
            <LicenseUpdatePopup setLicenseUpdate={setLicenseUpdate} />
          )}
          <div
            className={`MyCoursesTopBG flex flex-col px-[3%]
                            pt-28 pb-4 sm:px-[3%] md:px-[5%] lg:px-[14%]
                            
                            xl:px-[20%]`}
          >
            <h2
              className={`Boldfont m-0 text-center
                        text-base text-white sm:text-[30px] md:text-[34px] lg:text-[38px] 
                        xl:text-[45px] pb-3`}
            >
              {CoursePackage?.InstituteCourse?.Course?.CourseName}
            </h2>
            <p className="text-center text-5xs font-semibold text-white lg:text-3xs 2xl:text-2xs">
              {CoursePackage?.DrivingHours +
                " Hours Driving, " +
                CoursePackage?.InClassHours +
                " Hours In Class, " +
                CoursePackage?.OnlineHours +
                " Hours Online"}
            </p>

            <TabsSection activeTab={activeTab} setActiveTab={setActiveTab} handleTabClick={scrollToSection} />

          </div>
          <div className=" flex w-full flex-col items-center bg-white px-5 py-10 pt-16">
            <div ref={clientRef} className="backColor1 my-5  w-full flex-col items-center">
              <ClientInfo Student={Student} />
              <LastLicense
                StudentInfoId={Student?.StudentInfo?.StudentInfoId}
                imageUrl={imageUrl}
                setLicenseFullView={setLicenseFullView}
                setLicenseUpdate={setLicenseUpdate}
              />
            </div>
            <div ref={instructorsRef} className="mb-5 w-full">
              <p className=" box-border  w-full border-4 pb-5 text-center text-xl font-black text-purpal">
                Assign an Instructor
              </p>
              <div className="backColor1 w-full">
                <CourseInstructors
                  ShowSlider={ShowSlider}
                  setShowSlider={setShowSlider}
                  Instructors={Instructors}
                  setOneInstructor={setOneInstructor}
                  OneInstructor={OneInstructor}
                  setStudentPrefrredTimeTable={setStudentPrefrredTimeTable}
                />
              </div>
            </div>

            {Array.isArray(StudentPrefrredTimeTable) &&
              StudentPrefrredTimeTable.length > 1 && (
                <div className="w-full">
                  <p className=" box-border  w-full border-4  pb-5 text-center text-xl font-black text-purpal">
                    Instructor & Client Schedule
                  </p>

                  <div className="backColor1 w-full">
                    {/* <SchedulerCompleted
            Events={StudentPrefrredTimeTable}
            setEvents={setStudentPrefrredTimeTable}
            /> */}
                    <StudentScheduleTimeTable
                      Events={
                        lastFiveValues.length > 1
                          ? lastFiveValues
                          : StudentPrefrredTimeTable
                      }
                      setEvents={setStudentPrefrredTimeTable}
                    />
                  </div>
                </div>
              )}

            <div ref={timetableRef}  className="w-full ">
              <p className="box-border  w-full border-4  pb-5 text-center text-xl font-black text-purpal">
                Set Time Table
              </p>
              <div className="backColor1 w-full">
                <StudentTimeTable
                  timeTable={"assignSchedule"}
                  StudentInfo={Student}
                  Events={
                         StudentPrefrredTimeTable
                      }
                  // Events={[
                  //   ...StudentEnrollmentSelectedTimeTable.map(event => ({ ...event, type: 'enrollment' })),
                  //   ...StudentPrefrredTimeTable.map(event => ({ ...event, type: 'preferred' }))
                  // ]}
                  StudentEnrollmentSelectedTimeTable={StudentEnrollmentSelectedTimeTable}
                  setEvents={setEvents}
                  Resources={[
                    { fieldExpr: "Instructors", dataSource: [...Instructors] },
                    { fieldExpr: "ClassType", dataSource: [...ClassType] },
                    // { fieldExpr: 'EventType', dataSource: [
                    //   { id: 'enrollment', text: 'Enrollment Time' },
                    //   { id: 'preferred', text: 'Preferred Time' }
                    // ]}
                  ]}
                  Edit={true}
                  SubmitSchedule={() =>
                    SubmitData(
                      Events,
                      Dispatch,
                      Instructors,
                      OneInstructor,
                      EnrollmentId,
                      Student
                    )
                  }
                />
              </div>
            </div>

            {ShowSlider?.Show ? (
              <InstructorSlider
                setInstructors={setInstructors}
                Instructors={Instructors}
                ShowSlider={ShowSlider}
                setShowSlider={setShowSlider}
                InstructorType={ShowSlider?.InstructorType}
                Events={Events}
                setEvents={setEvents}
                SubmitSchedule={() =>
                  SubmitData(Events, Dispatch, Instructors, OneInstructor)
                }
              />
            ) : null}
          </div>
        </>
      ) : (
        <LoadingSpinner
          Bg="white"
          Width="full"
          Height="screen"
          Left="0"
          Position="fixed"
        />
      )}
    </>
  );
}

//expand recurring rule events;
function expandRecurringEvents(events) {
  let expandedEvents = [];

  events.forEach((event) => {
    if (event.recurrenceRule) {
      // Parse the recurrence rule
      let rule = rrulestr(`RRULE:${event.recurrenceRule}`, {
        dtstart: new Date(event.startDate),
      });

      // Generate occurrences
      let occurrences = rule.all();

      occurrences.forEach((date) => {
        let newEvent = { ...event };
        newEvent.startDate = new Date(date);
        let endDate = new Date(date);
        endDate.setMinutes(
          endDate.getMinutes() +
            (new Date(event.endDate) - new Date(event.startDate)) / 60000
        );
        newEvent.endDate = new Date(endDate);
        delete newEvent.recurrenceRule;
        expandedEvents.push(newEvent);
      });
    } else {
      expandedEvents.push(event);
    }
  });

  return expandedEvents;
}

const SubmitData = (
  Events,
  Dispatch,
  Instructors,
  OneInstructor,
  EnrollmentId,
  Student
) => {
  const InsId = GetLocalStorage("UserInfo")?.Institute?.InstituteId;

  let AssignedInstructors = [];

  Instructors.forEach((element) => {
    // console.log("element",element)
    if (!OneInstructor && element?.InstructorFK)
      AssignedInstructors.push({
        InstructorFK: element && element?.InstructorFK,
        EnrollmentFK: EnrollmentId,
      });
    else if (
      OneInstructor &&
      element &&
      element.InstructorType === "Driving" &&
      element?.InstructorFK
    )
      AssignedInstructors.push({
        InstructorFK: element?.InstructorFK,
        EnrollmentFK: EnrollmentId,
      });
  });

  console.log("AssignedInstructors=>", AssignedInstructors);

  const UserEmail = Student?.Email;
  const UserFirstName = Student?.FirstName;
  const UserLastName = Student?.LastName;

  Events = Events.map((value) => {
    value.EnrollmentFK = EnrollmentId;
    return value;
  });

  let expandedEvents = expandRecurringEvents(Events);

  console.log("Expanded Events", expandedEvents);
  console.log("Events2", Events);

  Dispatch(
    CreateScheduleA({
      Events: expandedEvents,
      AssignedInstructors,
      UserEmail: UserEmail,
      InsId: InsId,
      UserFirstName: UserFirstName,
      UserLastName: UserLastName,
      EnrollmentId: EnrollmentId,
    })
  );
};

const ManageNewStudent = () => (
  <InstituteTemplate
    Element={ManageNewStudentChild}
    m_top="mt-0"
    bg="bg-[#F0F0F7]"
  />
);

export default ManageNewStudent;
