import React, { useState } from 'react';
import { Alert, IconButton  } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from '@mui/icons-material/Info';


export function CheckboxBtns({ Days, BorderStyle, TimeIndex, Change, EnrollmentData, DaysIndex, TimeArr, selectedSlotsCount }) {
    const [showAlert, setShowAlert] = useState(false); // State to manage showing the alert

    const handleClose = () => {
        setShowAlert(false); // Close the alert when the button is clicked
      };
    // Check if the current time slot is selected (array of selected time slots)
    const isChecked = EnrollmentData.StudentData.Schedule[Days[TimeIndex]]?.includes(TimeArr[DaysIndex]);

    const handleCheckboxChange = (e) => {
        const checked = e.target.checked;
        const currentSchedule = EnrollmentData.StudentData.Schedule[Days[TimeIndex]] || [];

        // Check if the user has already selected two slots for the current day
        if (checked && selectedSlotsCount >= 2) {
            // Show the alert when more than 2 slots are selected
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 5000); // Automatically hide the alert after 3 seconds
            return;
        }

        if (checked) {
            // Add the time slot if checked
            Change(Days[TimeIndex], [...currentSchedule, TimeArr[DaysIndex]]);
        } else {
            // Remove the time slot if unchecked
            Change(Days[TimeIndex], currentSchedule.filter((time) => time !== TimeArr[DaysIndex]));
        }
    };

    return (
        <>
             {/* Conditionally render the MUI Alert */}
             {showAlert && (
                <Alert severity="info"
                // icon={false}  // Remove the severity icon
                  style={{
                     backgroundColor: '#4C34AD',  // Change the background color
                     color: '#fff',
                    position: 'absolute',
                    top: '20px',
                    left: '50%',
                    transform: 'translateX(-50%)', // This centers the alert horizontally
                    width: '700px', // Set the width to 700px
                }}
                iconMapping={{
            info: <InfoIcon style={{ color: '#fff' }} />  // Customize the icon color to white
        }}

                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                    }>
                    You can select only two time slots per day.
                </Alert>
            )}
       
            <span className={`w-52 flex justify-center py-5 ${BorderStyle}`}>
                <label className='Checkbox-Btn'>
                    <input
                        className='relative left-1/2 -translate-x-1/2'
                        value={TimeArr[DaysIndex]}
                        type="checkbox"
                        name={`${Days[TimeIndex]}`}
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    />
                    <span className='CheckMark'></span>
                </label>
            </span>
        </>
    );
}
