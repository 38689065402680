import React, { useEffect, useState } from "react";
import TimeItem from "./TimeItem.jsx";
import { Button } from "@mui/material";
import { getWeekClasses, getWeekTiming } from "../Helpers/utils.js";
import WeekTimeTableNew from "./WeekTimeTableNew.jsx";

const defaultWeekTiming = getWeekTiming();
const defaultWeekClasses = getWeekClasses();

const InstituteTimings = (props) => {
  const { onSkipHandler, onNextHandler, DocumentRef, UserInfoRef } = props;
  const [weekDays, setWeekDays] = useState(defaultWeekTiming);
  const [schedule, setSchedule] = useState(defaultWeekClasses);

  useEffect(()=>{
  
  const filterSchedule= defaultWeekClasses.filter((classItem) =>{
    const day = weekDays.find((day) => day.name === classItem.day);
    return day && day.isOpen;
  }
  )
setSchedule(filterSchedule)   
console.log(filterSchedule,weekDays.filter(week=>week.isOpen))
   },[weekDays])

  const handleChangeTime = (e, dayName, type) => {
    setWeekDays((prevState) =>
      prevState.map((day) =>
        day.name === dayName
          ? { ...day, time: { ...day.time, [type]: e.target.value } }
          : day
      )
    );
  };
  const handleDayEnable = (e, name) => {
    const openedWeekTime = weekDays.map((day) =>
      day.name === name ? { ...day, isOpen: e.target.checked } : day
    );

    setWeekDays(openedWeekTime);
   
  };

  const handleScheduleChange = (dayIndex, periodIndex, newTimeRange) => {
    setSchedule((prevSchedule) => {
      return prevSchedule.map((day, dIndex) => {
        if (dIndex === dayIndex) {
          const newTimetable = day.timetable.map((period, pIndex) => {
            if (pIndex === periodIndex) {
              return { ...period, ...newTimeRange };
            }
            return period;
          });
          return { ...day, timetable: newTimetable };
        }
        return day;
      });
    });
  };

  return (
    <div className="h-full w-full">
      <div className="flex flex-col ">
        <div className="flex h-40 flex-col items-center justify-center gap-2 bg-indigo text-white">
          <h2>Classes and Institute Timings</h2>
          <p className="text-9xs">
            Select opening hours for W/24 for your Driving School
          </p>
        </div>

        {/* time table contents */}
        <div className="my-7 flex w-full flex-col items-center justify-center gap-7">
          {/* Week open days */}
          <div className="flex flex-col gap-5 rounded-md bg-mediumWhite p-5">
            {weekDays.map((day) => (
              <TimeItem
                key={day.name}
                day={day}
                disabled={!day.isOpen}
                onChangeTime={handleChangeTime}
                onDayEnable={handleDayEnable}
              />
            ))}
          </div>

          {/*daily Time table  */}
          <div className="flex flex-col gap-5">
            <div className="flex flex-col items-center justify-center">
              <h2>Classes arrangments</h2>
              <p className="text-9xs">Enter classes hourly</p>
              <p className="text-9xs">Schedule for weekly basis</p>
            </div>
            {/* <WeekTimeTable/> */}
            <WeekTimeTableNew
              schedule={schedule}
              onChange={handleScheduleChange}
            />
            <div className="flex items-center justify-center gap-4">
              <Button
                sx={{ textTransform: "capitalize" }}
                className="bg-primary px-10"
                variant="contained"
                onClick={onSkipHandler}
              >
                Skip for now
              </Button>
              <Button
                sx={{ textTransform: "capitalize" }}
                className="bg-primary px-10"
                variant="contained"
                onClick={() => onNextHandler(weekDays, schedule)}
              >
                Next step
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstituteTimings;
