import React, { useState, useEffect } from "react";
import ClassScheduler from "../../../Components/Scheduler/Scheduler";
import MyCourses from "../../../Components/MyCourses/MyCourses";
import { TeacherInfoButtonsForStaffAdmin } from "../../../PageNames";
import InstituteTemplate from "../../../Components/InstituteTemplate/InstituteTemplate";
import useAxios from "../../../Helpers/CustomHooks/useAxios";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";
import { GetLocalStorage } from "../../../Helpers/LocalStorage/LocalStorage";
import useCheckLogin from "../../../Helpers/CustomHooks/CheckLogin";
import EnrolledCourseInfo from "../../EnrolledCourses/Component/EnrolledCourseInfo/EnrolledCourseInfo";
const TeacherTimeTableChild = ({ AssignedCourses }) => {
  const [Events, setEvents] = useState([]);
  const { InstructorId } = useParams();
  let InstrucId = InstructorId;
  if (!InstructorId)
    InstrucId = GetLocalStorage("UserInfo")?.Instructor?.InstructorId;

  const [res, loading] = useAxios(
    "get",
    `/api/teacher/schedule/${InstrucId}`
  );
  const AddLeaveBtn = () => (
    <button
      type="button"
      class="focus:ring-blue-300 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-5 mb-2 cursor-pointer rounded-lg border-0 bg-[#4c34ad] px-10 py-2 text-4xs font-medium text-white outline-none hover:bg-[#a1a3ef] focus:outline-none focus:ring-4 md:text-2xs lg:text-[22px] xl:py-4"
    >
      Add a Leave/Break
    </button>
  );
  useEffect(() => {
    if (res?.data)
      setEvents(
        res?.data.map((value) => {
          value.endDate = new Date(value.endDate);
          value.startDate = new Date(value.startDate);
          return value;
        })
      );
  }, [res]);

  console.log("Events", Events);
  // console.log(AssignedCourses);
  return !loading ? (
    <div className="flex min-h-screen flex-col items-center bg-[#F7F7F7] pt-28">
      {/* {AssignedCourses?.map((AssignedCourse) => (
                <div
                    className="w-[87%]"
                    key={AssignedCourse?.CourseEnrollment?.EnrollmentId}
                >
                    <EnrolledCourseInfo
                        Course={AssignedCourse?.CourseEnrollment}
                    />
                </div>
            ))} */}
      <ClassScheduler
        Edit={false}
        TopBtn={AddLeaveBtn}
        Events={Events}
        setEvents={setEvents}
      />
    </div>
  ) : (
    <LoadingSpinner
      Bg="white"
      Position="fixed"
      Height="screen"
      Width="full"
      Left="0"
      Top="0"
    />
  );
};
const TeacherTimetablePage = () => {
  const { InstructorId } = useParams();
  const [Res] = useAxios("get", `/api/Instructor/courses/${InstructorId}`);
  const [AssignedCourses, setAssignedCourses] = useState();
  useEffect(() => {
    setAssignedCourses(Res?.data);
  }, [Res, InstructorId, AssignedCourses]);
  console.log("AssignedCourses", AssignedCourses);
  return (
    <MyCourses
      ButtonsInfo={TeacherInfoButtonsForStaffAdmin}
      PageName="ViewTimeSchedule"
      Param={InstructorId}
      HideFooter={true}
      DisplayName={AssignedCourses?.[0]?.Instructor?.Name}
    >
      <TeacherTimeTableChild AssignedCourses={AssignedCourses} />
    </MyCourses>
  );
};
const TeacherTimetable = () => (
  <InstituteTemplate
    Element={TeacherTimetablePage}
    mt="0"
    // HideSideBar={true}
  />
);
export default TeacherTimetable;
