import ClassesIndicator from "../../Components/ClassesIndicator/ClassesIndicator";
import Timetable from "./TimeTable/Timetable";
import "./Scheduler.css";
import { useSelector } from "react-redux";
import AssignScheduleTimetable from "./TimeTable/AssignScheduleTimetable";

function ClassScheduler({
  Name,
  TopBtn,
  BottomComp,
  setEvents,
  Events,
  Edit,
  Resources,
  studentSchedule,
  timeTable,
}) {

    const {CoursePackage } = useSelector(
        (Store) => Store.StudentReducer
      );



  return (

    <div className="TimeTableContainer relative top-[-113px] lg:top-[-69px] ">
      <div className="flex h-fit w-full flex-col items-center justify-start gap-7 bg-white px-0 sm:w-[87%] sm:px-1 md:px-1 lg:px-6 xl:px-7 2xl:px-8">
        <div className="flex w-full justify-between">
          {/* <h3 className="text-xs font-normal text-[#4D4F5C]">{Name}</h3> */}
        </div>

        <div className="flex w-full flex-col items-center justify-between gap-5 lg:flex-row">
          <div className="flex w-full items-center justify-center lg:w-1/3 lg:justify-start">
            <ClassesIndicator 
            text="sm:text-5xs md:text-4xs xl:text-3xs"
            CoursePackage={CoursePackage}
            timeTable={timeTable}
             />
          </div>

          <div className="flex w-[55%]  flex-col items-center justify-between gap-3 sm:flex-row">
            {/* <p className="top-5 whitespace-nowrap text-center text-3xs text-[#4D4F5C] md:text-2xs lg:relative lg:text-xs xl:text-base">
                        </p> */}
          
            <div>{TopBtn && <TopBtn />} </div>
          </div>
        </div>

        <div className="w-full overflow-x-scroll">
          <div className="w-full min-w-[350px] ">
            {timeTable === "assignSchedule" ? <AssignScheduleTimetable  Events={Events}
                setEvents={setEvents}
                Edit={Edit}
                Resources={Resources}
                studentSchedule={studentSchedule}
                CoursePackage={CoursePackage}
                /> :  <Timetable
                Events={Events}
                setEvents={setEvents}
                Edit={Edit}
                Resources={Resources}
                studentSchedule={studentSchedule}
              />}
             
    
          </div>
        </div>
        {BottomComp && <BottomComp />}
      </div>
    </div>
  
  );
}

export default ClassScheduler;
