// react States
import React, { useEffect, useState } from "react";
import { PackageOnChange } from "../Helpers/Events";

const CreateCoursePackage = ({
  Packages,
  setPackages,
  Err,
  setErr,
  CourseData,
  update,
}) => {
  const [CourseErrs, setCourseErrs] = useState([]);
  const [showSelect, setShowSelect] = useState(false);
  const [packagesData, setPackagesData] = useState({
    PackageName: "",
    DrivingHours: 1,
    InClassHours: 1,
    OnlineHours: 1,
    DiscountType: "no_discount",
    AddDiscount: "",
    TotalFee: 1,
    InstallmentSchedule: "Weekly",
    Installments: 1,
  });
  const OnChangeSetPackages = (e, Name) => {
    let data = e.target.value;
    console.log("data", data, "name", Name);
    if (Name === "DiscountType" && data === "add_discount") {
      setShowSelect(true);
    }

    setPackagesData({ ...packagesData, [Name]: data });
  };
  //   console.log("Packages", Packages);
  const AddPackageData = () => {
    setShowSelect(false);
    setPackages([...Packages, packagesData]);
    setErr({ ...Err, Packages: [...CourseErrs] });
    setPackagesData({
      PackageName: "",
      DrivingHours: 1,
      InClassHours: 1,
      OnlineHours: 1,
      DiscountType: "no_discount",
      AddDiscount: "",
      TotalFee: 1,
      InstallmentSchedule: "Weekly",
      Installments: 1,
    });
  };
  const AddPackage = (e) => {
    let EmptyPackage = {
      PackageName: "",
      DrivingHours: 1,
      InClassHours: 1,
      OnlineHours: 1,
      DiscountType: 1,
      TotalFee: 1,
      InstallmentSchedule: "Weekly",
      Installments: 1,
    };
    setPackages([...Packages, EmptyPackage]);
    setErr({ ...Err, Packages: [...CourseErrs] });
  };

  const RemovePackage = (Index) => {
    let RemovedPackages = Packages.filter((value, index) => Index !== index);
    setPackages(RemovedPackages);
    if (RemovedPackages.length <= 0)
      setErr({ ...Err, Packages: "At least one package is required" });
  };

  const onChange = (e, INDEX, ClassType, ClassName) =>
    PackageOnChange(
      e,
      INDEX,
      ClassType,
      ClassName,
      Packages,
      setPackages,
      CourseErrs,
      setCourseErrs
    );

  useEffect(() => {
    setErr({ ...Err, Packages: [...CourseErrs] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CourseErrs]);

  return (
    <div className="packageTable" id="Packages">
      {/* <h2>Packages</h2> */}
      <h2 className="font-semibold text-[#4C34AD]">Packages</h2>
      <table
        className="addCourse4Table"
        style={{
          background:
            "transparent linear-gradient(114deg, #ffffff 0%, #f6f6ff 14%, #d6d8ff 100%) 0% 0%no-repeat padding-box",
        }}
      >
        <thead>
          <tr className=" ">
            <th className="w-[10%] max-w-[150px] py-3 ">#</th>
            <th className="w-[12%] max-w-[150px] py-3  ">Package Names</th>

            <th className="w-[12%] max-w-[150px] py-3">In-Vehicle Hours</th>
            <th className="w-[12%] max-w-[150px] py-3">In-Class Hours</th>
            <th className="w-[12%] max-w-[150px] py-3">Online / Others</th>
            <th className="w-[20%]  py-3">Discount Type</th>
            <th className="w-[12%] max-w-[150px] py-3">Fee</th>
            <th className="w-[12%] max-w-[150px] py-3">Installments</th>
            <th className="w-[12%] max-w-[150px] py-3">Status</th>
          </tr>
        </thead>
        <tbody>
          {Packages.length > 0
            ? Packages?.map((value, index) => (
                <NewPackge
                  update={update}
                  key={index}
                  value={value}
                  Packages={Packages}
                  onChange={onChange}
                  RemovePackage={RemovePackage}
                  index={index}
                />
              ))
            : null}

          {!update && (
            <tr className="border-0 border-b border-solid border-[#A5C9FF] p-2 py-3 ">
              <td className="max-w-[150px]">
                <div>
                  <input
                    type="number"
                    placeholder="#"
                    disabled={true}
                    // min={1}
                    className="RemoveInputNumArrows h-full w-full border-none px-6 py-2 text-center text-center text-2xs"
                    // value={"#"}
                    // onChange={(e) =>
                    //     onChange(e, index, "DrivingHours", "Driving Hours")
                    // }
                  />
                </div>
              </td>
              <td className="max-w-[150px]">
                <input
                  type="text"
                  // min={1}
                  className="RemoveInputNumArrows h-full w-full border-none px-6 py-2 text-center text-2xs"
                  value={packagesData.PackageName}
                  onChange={(e) => OnChangeSetPackages(e, "PackageName")}
                />
              </td>
              <td className="max-w-[150px]">
                <input
                  type="number"
                  min={1}
                  className="RemoveInputNumArrows h-full w-full border-none px-6 py-2 text-center text-2xs"
                  value={packagesData.DrivingHours}
                  onChange={(e) => OnChangeSetPackages(e, "DrivingHours")}
                />
              </td>

              <td className="max-w-[150px]">
                <input
                  type="number"
                  min={1}
                  className="RemoveInputNumArrows h-full w-full border-none px-6 py-2 text-center text-2xs"
                  value={packagesData.InClassHours}
                  onChange={(e) => OnChangeSetPackages(e, "InClassHours")}
                />
              </td>
              <td className="max-w-[150px]">
                <input
                  type="number"
                  min={1}
                  className="RemoveInputNumArrows h-full w-full border-none px-6 py-2 text-center text-2xs"
                  value={packagesData.OnlineHours}
                  onChange={(e) => OnChangeSetPackages(e, "OnlineHours")}
                />
              </td>

              <td className="max-w-[150px]">
                {!showSelect ? (
                  <select
                    className=" h-full w-full border-none bg-white px-6 py-2 text-center text-2xs"
                    value={packagesData.DiscountType}
                    onChange={(e) => OnChangeSetPackages(e, "DiscountType")}
                  >
                    <option value="no_discount">No Discount</option>
                    <option value="no_tax">No Tax</option>
                    <option value="add_discount">Add Discount</option>
                  </select>
                ) : (
                  <input
                    type="text"
                    // min={0}
                    className="RemoveInputNumArrows h-full w-full border-none px-6 py-2 text-center text-2xs"
                    value={packagesData.AddDiscount}
                    onChange={(e) => OnChangeSetPackages(e, "AddDiscount")}
                  />
                )}
              </td>

              <td className="max-w-[150px]">
                <input
                  type="number"
                  min={1}
                  className="RemoveInputNumArrows h-full w-full border-none px-6 py-2 text-center text-2xs"
                  value={packagesData.TotalFee}
                  onChange={(e) => OnChangeSetPackages(e, "TotalFee")}
                />
              </td>
              <td className="max-w-[150px]">
                <input
                  type="number"
                  min={1}
                  className="RemoveInputNumArrows h-full w-full border-none px-6 py-2 text-center text-2xs"
                  value={packagesData.Installments}
                  onChange={(e) => OnChangeSetPackages(e, "Installments")}
                />
              </td>
              <td
              // className="statusAddCourseTableBtn "
              // onClick={AddPackageData}
              >
                <div
                  className="statusAddCourseTableBtn flex items-center justify-center "
                  onClick={AddPackageData}
                >
                  Add
                </div>
              </td>
            </tr>
          )}

          {/* <tr className="addCOurseRowLast">
                        <td>Min 1</td>
                        <td>Min 1</td>
                        <td>Min 1</td>
                        <td>Min 1</td>
                        <td>Min 1</td>
                        <td>Type</td>
                        <td>1 $</td>
                        <td>1</td>
                        <td
                            className="statusAddCourseTableBtn"
                            onClick={AddPackage}
                        >
                            Add
                        </td>
                    </tr> */}
        </tbody>
      </table>
      {CourseErrs.map((value, index) => (
        <h3
          key={index + Object.keys(value)?.[0]}
          className="font-normal text-[red]"
        >
          {value?.[Object.keys(value)?.[0]]}{" "}
        </h3>
      ))}
      <h3 className="text-3xs font-normal text-[red]">
        {typeof Err?.Packages === "string" ? Err?.Packages : null}
      </h3>
    </div>
  );
};

const NewPackge = ({
  Packages,
  value,
  onChange,
  index,
  RemovePackage,
  showSelect,
  update,
}) => {
  console.log("value", value);
  const {
    PackageName,
    DrivingHours,
    OnlineHours,
    InClassHours,
    DiscountType,
    TotalFee,
    Installments,
    AddDiscount,
  } = value;
  return (
    <tr className="border-0 border-b border-solid border-[#A5C9FF]">
      <td className="max-w-[150px]">
        {/* <input
                    type="number"
                    min={index}
                    className="RemoveInputNumArrows h-full w-full border-none px-6 py-2 text-center text-2xs"
                    value={index + 1}
                    // onChange={(e) =>
                    //     onChange(e, index, "DrivingHours", "Driving Hours")
                    // }
                /> */}
        <div className="flex items-center justify-center">{index + 1}</div>
      </td>
      <td className="max-w-[150px]">
        {/* <input
                    type="text"
                    // min={1}
                    className="RemoveInputNumArrows h-full w-full border-none px-6 py-2 text-center text-2xs"
                    value={value?.PackageName}
                    onChange={(e) =>
                        onChange(e, index, "PackageName", "Package Name")
                    }
                /> */}
        <div className="flex items-center justify-center">{PackageName}</div>
      </td>
      <td className="max-w-[150px]">
        {/* <input
                    type="number"
                    min={1}
                    className="RemoveInputNumArrows h-full w-full border-none px-6 py-2 text-center text-2xs"
                    value={value?.DrivingHours}
                    onChange={(e) =>
                        onChange(e, index, "DrivingHours", "Driving Hours")
                    }
                /> */}
        <div className="flex items-center justify-center">{DrivingHours}</div>
      </td>

      <td className="max-w-[150px]">
        {/* <input
                    type="number"
                    min={1}
                    className="RemoveInputNumArrows h-full w-full border-none px-6 py-2 text-center text-2xs"
                    value={value?.InClassHours}
                    onChange={(e) =>
                        onChange(e, index, "InClassHours", "InClass Hours")
                    }
                /> */}
        <div className="flex items-center justify-center">{InClassHours}</div>
      </td>
      <td className="max-w-[150px]">
        {/* <input
                    type="number"
                    min={1}
                    className="RemoveInputNumArrows h-full w-full border-none px-6 py-2 text-center text-2xs"
                    value={value?.OnlineHours}
                    onChange={(e) =>
                        onChange(e, index, "OnlineHours", "Online Hours")
                    }
                /> */}
        <div className="flex items-center justify-center">{OnlineHours}</div>
      </td>

      <td className="max-w-[150px]">
        {/* <input
                    type="number"
                    min={0}
                    className="RemoveInputNumArrows h-full w-full border-none px-6 py-2 text-center text-2xs"
                    value={value?.DiscountType}
                    onChange={(e) =>
                        onChange(e, index, "DiscountType", "Discout Type")
                    }
                /> */}
        <div className="flex items-center justify-center">
          {!showSelect && DiscountType !== "add_discount"
            ? // Display DiscountType if showSelect is false and DiscountType is not "add_discount"
              DiscountType
            : // Display AddDiscount if showSelect is true or DiscountType is "add_discount"
              AddDiscount}
        </div>
      </td>

      <td className="max-w-[150px]">
        {/* <input
                    type="number"
                    min={1}
                    className="RemoveInputNumArrows h-full w-full border-none px-6 py-2 text-center text-2xs"
                    value={value?.TotalFee}
                    onChange={(e) =>
                        onChange(e, index, "TotalFee", "Total Fee")
                    }
                /> */}
        <div className="flex items-center justify-center">{TotalFee}</div>
      </td>
      <td className="max-w-[150px]">
        {/* <input
                    type="number"
                    min={1}
                    className="RemoveInputNumArrows h-full w-full border-none px-6 py-2 text-center text-2xs"
                    value={value?.Installments}
                    onChange={(e) =>
                        onChange(e, index, "Installments", "Installments")
                    }
                /> */}
        <div className="flex items-center justify-center">{Installments}</div>
      </td>
      <td
      // className="statusAddCourseTableBtn "
      // onClick={() => {
      //     RemovePackage(index);
      // }}
      >
        <div
          className="statusAddCourseTableBtn px-5 text-center "
          onClick={() => {
            if (!update) RemovePackage(index);
          }}
        >
          Delete
        </div>
      </td>
    </tr>
  );
};
export default CreateCoursePackage;
