import React, { useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { verifingOtp } from "./api";

const OTPModal = ({ open, handleClose, onStdudentVerifing }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const inputRefs = useRef([]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setError("");

      if (value && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = otp.every((digit) => digit !== "" && !isNaN(digit));
    if (isValid) {
      // Handle OTP submission logic here
      setIsLoading(true);
      const response = await verifingOtp(otp.join(""));
      setIsLoading(false);
      if (response.message === "verified") {
        onStdudentVerifing(Boolean(response.message));
      } else if (response.error) setError(response.error);
    } else {
      setIsLoading(false);
      setError("Please enter the OTP shared by student");
    }
  };

  return (
    <Dialog open={open} aria-labelledby="dialog-title">
      <DialogTitle
        id="dialog-title"
        className="flex items-center justify-between"
      >
        <span>Please Insert OTP To Start The Class</span>
        <IconButton
          onClick={handleClose}
          size="small"
          sx={{ backgroundColor: "transparent" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Typography sx={{ mt: 2 }} className="text-center">
            A verification code has been sent to{" "}
            <span className="font-bold">Student Name</span> phone number. Please
            enter the code to start the class.
          </Typography>
          <div className="mt-4 flex justify-center space-x-2">
            {otp.map((digit, index) => (
              <TextField
                key={index}
                value={digit}
                onChange={(e) => handleChange(e, index)}
                variant="outlined"
                inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                className="h-12 w-12"
                inputRef={(el) => (inputRefs.current[index] = el)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            ))}
          </div>
          {error && (
            <Typography color="error" className="!my-4 text-center">
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <div className="flex w-full justify-center py-5">
            <Button
              variant="contained"
              type="submit"
              className="bg-violet-100"
              disabled={isLoading}
            >
              {!isLoading ? "Verify" : "Verifing..."}
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default OTPModal;
