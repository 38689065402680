import React from 'react';

const RadioBtnClasses = ({ text, CoursePackage, timeTable, handleClassTypeChange, selectedClassType }) => {
    let SpanStyle = `whitespace-nowrap text-white border-[1px] border-solid border-[#D7DAE2] h-fit
    py-3        sm:py-2         lg:py-3      xl:py-3 
    px-4        sm:px-3         lg:px-4      xl:px-5 
    px-4        md:px-5  
    text-3xs`;
    
    return (
        <span className='flex justify-center gap-5 md:justify-end w-fit '>
            {/* Driving Option */}
            <span className='flex flex-col gap-3 items-center'>
                <label className="flex items-center gap-2">
                    <input type="radio" name="classType" value="Driving" className="w-[15px] h-[15px] bg-transparent"
                    checked={selectedClassType === "Driving"}  onChange={handleClassTypeChange} />
                    <button className={`${SpanStyle} ${text} bg-[#A1A3EF] rounded`}>Driving</button>
                </label>
                {timeTable === "assignSchedule" ? <span>{CoursePackage?.DrivingHours ? CoursePackage?.DrivingHours : 0}</span> : null}
            </span>

            {/* Online Option */}
            <span className='flex flex-col gap-3 items-center'>
                <label className="flex items-center gap-2">
                    <input type="radio" name="classType"
                     value="Online" className="w-[15px] h-[15px] bg-transparent"
                     
                     checked={selectedClassType === "Online"}
                     onChange={handleClassTypeChange} />
                    <button className={`${SpanStyle} ${text} bg-[#7F82F9] rounded`}>Online</button>
                </label>
                {timeTable === "assignSchedule" ? <span>{CoursePackage?.OnlineHours ? CoursePackage?.OnlineHours : 0}</span> : null}
            </span>

            {/* In Class Option */}
            <span className='flex flex-col gap-3 items-center'>
                <label className="flex items-center gap-2">
                    <input type="radio" name="classType" value="InClass"
                    
                     checked={selectedClassType === "InClass"}
                    className="w-[15px] h-[15px] bg-transparent"  onChange={handleClassTypeChange} />
                    <button className={`${SpanStyle} ${text} bg-[#5456C7] rounded`}>In Class</button>
                </label>
                {timeTable === "assignSchedule" ? <span>{CoursePackage?.InClassHours ? CoursePackage?.InClassHours : 0}</span> : null}
            </span>
        </span>
    );
};

export default RadioBtnClasses;
