import React from "react";
import TimeRangePicker from "./TimeRangPicker";
// import TimeRangePicker from './TimeRangePicker';

const WeekTimeTableNew = ({ schedule, onChange }) => {
  return (
    <div className="bg-mediumWhite p-4">
      <div className="grid grid-cols-6 gap-2">
        <div></div>
        {schedule[0].timetable.map((_, index) => (
          <div
            key={index}
            className="flex w-40 items-center py-2 px-4 text-center font-bold shadow-md"
          >
            <p> Class {index + 1}</p>
          </div>
        ))}
        {schedule.map((day, dayIndex) => (
          <React.Fragment key={day.day}>
            <div className="row-span-1 flex w-28 items-center justify-center py-2 px-4 font-bold shadow-md">
              <p>{day.day}</p>
            </div>
            {day.timetable.map((period, periodIndex) => {
           
              return (
                <div key={period.period}>
                    {period.start ?  <TimeRangePicker
                    value={{ start: period.start, end: period.end }}
                    onChange={(newTimeRange) =>
                      onChange(dayIndex, periodIndex, newTimeRange)
                    }
                  />:<p>No Class</p>}
                
                </div>
              );
            })}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default WeekTimeTableNew;
