
import "devextreme/dist/css/dx.light.css";
import { Editing, Resource, Scheduler, View } from "devextreme-react/scheduler";
import { useAuthContext } from "../../../context/authContext";
import { useState } from "react";

function DevExtremeScheduler({ Events, setEvents, Edit, Resources, studentSchedule }) {
  console.log("student schedule in timetable is:",Events)
  
  function getInstructorType(id) {
    console.log("id", id)
    const instructor = Resources?.[0]?.dataSource.find((val) => val.InstructorFK === id);
    console.log("instructor", instructor)
    console.log("instructor.InstructorType", instructor.InstructorType)
    return instructor ? instructor.InstructorType : null;
  }
  

  const {setLastFiveValues, lastFiveValues} = useAuthContext()
  console.log("resources", Resources)
  const neww = Resources?.[0].dataSource.map((val)=> console.log("val", val))
  console.log("neww",neww)


  const renderTimeCell = (data) => (
    <i style={{ color: "green" }}>{data?.text}</i>
  );

  const OnUpdate = (e) => {
    if(studentSchedule === 'lastFiveValues') {
      const lastFive = Events.slice(-5);
      setLastFiveValues(lastFive);
    }

  };
  // console.log("lastFiveValues",lastFiveValues)
  

  const onAppointmentDeleting = (e) => {
    // console.log(e)
  };
  const onAppointmentDeleted = (e) => {
    // let Index = Events.indexOf(e?.appointmentData);
    // let NewEvents = Events.splice(Index, 1)

    setEvents([...Events]);
  };
  const onAppointmentAdding = (e) => {
  };
  const onAppointmentAdded = (e) => {
    const { appointmentData } = e;
    console.log("appoin", appointmentData.Instructors)

    const type = getInstructorType(appointmentData.Instructors)

    // console.log("appointmentData", appointmentData)
    const NewEvents = Events.map((value) => {
      if (
        value.endDate === appointmentData.endDate &&
        value.startDate === appointmentData.startDate &&
        value.startDate === appointmentData.startDate &&
        value.description === appointmentData.description &&
        value.text === appointmentData.text
      ) {
        value.InstructorFK = appointmentData.Instructors;
        // value.ClassType = appointmentData.ClassType;
        value.ClassType =  type
      }
      return value;
    });
    setEvents([...NewEvents]);
  };

  console.log("Events", Events)

  const customizeAppointment = (e) => {
    // Customize the color based on conditions
    let appointmentColor = "#3377b2";
    // console.log('eeee', e.appointmentData)
   

    if (e?.appointmentData?.CourseEnrollment?.Status === "Hold") {
      appointmentColor = "#d9534f";
    } else if (e?.appointmentData?.ClassType === "Online") {
      appointmentColor = "#7f82f9";
    } else if (e?.appointmentData?.ClassType === "InClass") {
      appointmentColor = "#5456c7";
    }else if (e?.appointmentData?.ClassType === "Driving") {

      appointmentColor = "#a1a3ef";
    }
    


    return (
      <div
        className=""
        id="newDiv"
        style={{
          backgroundColor: appointmentColor,
          border: "0",
          margin: "0",
          width: "calc(100% + 10px)", // Adjust for left and right padding (e.g., 5px on each side)
          height: "calc(100% + 10px)",
        }}
      >
        <div className="flex h-full w-full items-center justify-center">
          <p className="mb-2 mr-2 text-[10px]">
            {" "}
            { e?.appointmentData?.CourseEnrollment?.User?.FirstName }
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <Scheduler
        id="scheduler"
        dataSource={Events}
        currentView="week"
        views={["week", "month"]}
        startDayHour={9}
        endDayHour={19}
        onAppointmentUpdated={OnUpdate}
        onAppointmentDeleted={onAppointmentDeleted}
        onAppointmentDeleting={onAppointmentDeleting}
        onAppointmentAdding={onAppointmentAdding}
        onAppointmentAdded={onAppointmentAdded}
        showCurrentTimeIndicator={true}
        shadeUntilCurrentTime={true}
        appointmentRender={customizeAppointment}
      >
        <Editing allowDeleting={Edit} allowAdding={Edit} allowUpdating={Edit} />
        <View
          type="week"
          cellDuration={45}
          dateCellRender={renderTimeCell}
        />
        {/* {Resources?.length > 0
          ? Resources.map((value) => (
              <Resource
                key={value.fieldExpr}
                fieldExpr={value.fieldExpr}
                dataSource={value.dataSource}
              />
            ))
          : null} */}
          {Resources?.length > 0 ? <Resource
                key={Resources[0].fieldExpr}
                fieldExpr={Resources[0].fieldExpr}
                dataSource={Resources[0].dataSource}
                style={{ width: '100%' }} 
              />
           
          : null}
      </Scheduler>
    </>
  );
}

export default DevExtremeScheduler;

// [
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T04:00:00.000Z",
//     "endDate": "2024-06-24T04:30:00.000Z",
//     "Instructors": "66a862b2-86a2-4d5c-9353-f5709962e364",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   },
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T05:30:00.000Z",
//     "endDate": "2024-06-24T06:00:00.000Z",
//     "Instructors": "d0b3c6c1-2114-4c4a-a36c-37207abf5ae8",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   },
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T06:00:00.000Z",
//     "endDate": "2024-06-24T06:30:00.000Z",
//     "Instructors": "d0b3c6c1-2114-4c4a-a36c-37207abf5ae8",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   },
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T06:30:00.000Z",
//     "endDate": "2024-06-24T07:00:00.000Z",
//     "Instructors": "4b716892-63d8-47cf-815c-19d6f3bca83f",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   }
// ]



// [
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T04:00:00.000Z",
//     "endDate": "2024-06-24T04:30:00.000Z",
//     "Instructors": "4b716892-63d8-47cf-815c-19d6f3bca83f",
//     "ClassType": "Driving",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   },
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T05:00:00.000Z",
//     "endDate": "2024-06-24T05:30:00.000Z",
//     "Instructors": "d0b3c6c1-2114-4c4a-a36c-37207abf5ae8",
//     "ClassType": "Online",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   },
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T06:00:00.000Z",
//     "endDate": "2024-06-24T06:30:00.000Z",
//     "Instructors": "e57d1ea5-8bef-4fb1-a484-39beac9ceec5",
//     "ClassType": "InClass",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   }
// ]