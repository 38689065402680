import React, { useEffect, useState } from "react";
import {
  Input,
  SelectList,
} from "../../../../Components/InputComps/InputComps";
import { CountryOptions } from "../../../Institute/AddInstructor/Components/OptionsArr/OptionsArr";
import SearchLocationByInput from "../../../Institute/AddInstructor/Components/InstructorInfo/components/GooglePlacesApi/SearchLocationByInput";
import { City, Country, State } from "country-state-city";
import SearchLocationByInputStudent from "../../../Institute/AddInstructor/Components/InstructorInfo/components/GooglePlacesApi/SearchLocationByInputStudent";

const NewUserLocationInfo = ({
  Styling,
  setEnrollmentData,
  EnrollmentData,
  Err,
  setErr,
  OnChange,
  Addresses,
  setAddresses,
  UserInformation,
  newCountryPostalCode,
  newStatePostalCode,
  newCityPostalCode
}) => {
  const {
    InputTypeTextStyle,
    DivStyle,
    HeadingStyle,
    ExceptionSpanStyle,
    ExceptionInputStyle,
  } = Styling;
  let { AddParentStyle, RemoveParentStyle } = Styling;
  const [selectedCountry, setSelectedCountry] = useState(Addresses);
  const [cities, setCities] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState();
  const [SelectedCoordinates, setSelectedCoordinates] = useState(null);
  console.log("countryOption in header rightside:",CountryOptions)


  useEffect(() => {
    const selectedCountry = EnrollmentData.StudentData?.Country || newCountryPostalCode;
    console.log("selected country set in new location is:",selectedCountry)
    const selectedProvince = EnrollmentData.StudentData?.Province || newStatePostalCode;
    console.log("selected selectedProvince set in new location is:",selectedProvince)
    if (
      typeof selectedCountry === "string" &&
      typeof selectedProvince === "string"
    ) {
      const country = Country?.getAllCountries()?.find(
        (country) =>
          country?.name.toLowerCase() === selectedCountry?.toLowerCase()
      );
      if (country) {
        const states = State?.getStatesOfCountry(country?.isoCode);
        const selectedProvinceData = states?.find(
          (province) =>
            province.name.toLowerCase() === selectedProvince?.toLowerCase()
        );
        if (newCountryPostalCode || selectedProvinceData ) {
          console.log("Found Province: ", selectedProvinceData);
          const citiesData = City.getCitiesOfState(
            country?.isoCode,
            selectedProvinceData?.isoCode
          );
          setCities(citiesData);
        }
      }
    }
  }, [newCountryPostalCode, newStatePostalCode, EnrollmentData.StudentData?.Country, EnrollmentData.StudentData?.Province, EnrollmentData, selectedCountry, selectedProvince ]);
  const ProvinceOptions = () => {
    let states = [];

    // Determine the country to use
  const countryName = EnrollmentData?.StudentData?.Country || newCountryPostalCode;

    if (typeof countryName  === "string") {
      let countries = Country?.getAllCountries();

      const country = countries?.find(
        (country) =>
          country?.name.toLowerCase() ===
          countryName.toLowerCase()
      );

      if (country) {
        states = State?.getStatesOfCountry(country?.isoCode);
        // console.log("country", states);
      }
    }

    console.log("States", states);
    return states?.map((country) => (
      <option value={country.name} key={country.isoCode}>
        {country?.name}
      </option>
    ));
  };
  const CityOptions = () => {
    return (
      cities &&
      cities?.map((city, index) => (
        <option value={city?.name} key={index}>
          {city?.name}
        </option>
      ))
    );
  };

  return (
    <>
      
      <div className={DivStyle}>
        <Input
          readOnly={UserInformation?.StudentInfo?.PostalCode ? true : false}
          // Number="number"
          Label="Postal Code *"
          Placeholder="A1A 1A1"
          Id="PostalCode"
          Err={Err?.PostalCode}
          State={EnrollmentData?.StudentData?.PostalCode}
          onChange={(e) => OnChange(e, "PostalCode")}
        />
      </div>


      <div className={DivStyle}>
        {UserInformation && UserInformation?.StudentInfo?.Country ? (
          <>
            <Input
              Label="Country *"
              Id="Country"
              State={UserInformation?.StudentInfo?.Country}
              readOnly={true}
              onChange={(e) => OnChange(e, "Country")}
            />
          </>
        ) : (
          <SelectList
            // readOnly={readOnly}
            Label="Country *"
            Id="Country"
            defaultValue=""
            Text="Country"
            Err={Err?.Country}
            OptionsArr={CountryOptions}
            State={EnrollmentData?.StudentData?.Country || newCountryPostalCode}
            onChange={(e) => OnChange(e, "Country")}
          />
        )}

      </div>

      <div className={DivStyle}>

        {UserInformation && UserInformation?.StudentInfo?.Province ? (
          <>
            <Input
              Label="Province *"
              Id="Province"
              State={UserInformation?.StudentInfo?.Province}
              readOnly={true}
              onChange={(e) => OnChange(e, "Province")}
            />
          </>
        ) : (
          <SelectList
            // readOnly={readOnly}
            Label="Province / Territory *"
            Id="Province"
            defaultValue=""
            Text="Province / Territory"
            Err={Err?.Province}
            OptionsArr={ProvinceOptions}
            State={ newStatePostalCode || EnrollmentData?.StudentData?.Province}
            // State={InstructorData?.Province}
            onChange={(e) => OnChange(e, "Province")}
          />
        )}
      </div>

      <div className={DivStyle}>
        {UserInformation && UserInformation?.StudentInfo?.City ? (
          <>
            <Input
              Label="City *"
              Id="City"
              State={UserInformation?.StudentInfo?.City}
              readOnly={true}
              onChange={(e) => OnChange(e, "City")}
            />
          </>
        ) : (
          <SelectList
            Label="City *"
            Id="City"
            defaultValue=""
            Text="City"
            Err={Err?.City}
            OptionsArr={CityOptions}
            State={EnrollmentData?.StudentData?.City || newCityPostalCode}
            onChange={(e) => OnChange(e, "City")}
          />
        )}
      </div>

      <div className={DivStyle}>
        {UserInformation && UserInformation?.StudentInfo?.Address ? (
          <>
            <Input
              Label="Address*"
              Id="Address"
              State={UserInformation?.StudentInfo?.Address}
              readOnly={true}
              onChange={(e) => OnChange(e, "FirstName")}
            />
          </>
        ) : (
          <SearchLocationByInputStudent
            InstructorData={Addresses}
            setInstructorData={setAddresses}
            EnrollmentData={EnrollmentData}
            Err={Err?.Address}
            setErr={setErr}
            setSelectedCoordinates={setSelectedCoordinates}
            newCountryPostalCode={newCountryPostalCode}
            Id="Address"
          />
        )}
      </div>

    </>
  );
};

export default NewUserLocationInfo;
