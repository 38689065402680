
import "devextreme/dist/css/dx.light.css";
import { Editing, Resource, Scheduler, View } from "devextreme-react/scheduler";
import { useAuthContext } from "../../../context/authContext";
import { useState, useEffect } from "react";
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField'; // Import TextField here
import './AssignSchedule.css'

const getNextMonday=(date) =>{
  const resultDate = moment(date).clone().startOf('isoWeek').add(1, 'weeks'); // Get next Monday (ISO Week starts on Monday)
  return resultDate;
}

// Scheduling functions for each instructor
const firstInstructorSchedule = (events, drivingHours, firstInstructor, selectedDate) => {
  console.log("First instructor schedule called for:", selectedDate);

  const scheduledEvents = [];
  const startDay = selectedDate ? moment(selectedDate) : getNextMonday(new Date()); // Use selectedDate if provided, otherwise next Monday
  let drivingHourCount = 0;
  let currentDay = moment(startDay); // Start from the selected date or next Monday
  let lastDrivingEventEndDate = null;

  console.log("Initial Start Day:", startDay.format("YYYY-MM-DD"));

  // Group events by day and extract valid hours
  const eventsByDay = {};
  const hoursByDay = {};

  events.forEach(event => {
    const day = moment(event.startDate).day();
    if (!eventsByDay[day]) eventsByDay[day] = [];
    if (!hoursByDay[day]) hoursByDay[day] = new Set();

    eventsByDay[day].push(event);
    const startHour = moment(event.startDate).hour();
    const endHour = moment(event.endDate).hour();

    // Add all hours from start to end to the set for that day
    for (let hour = startHour; hour <= endHour; hour++) {
      hoursByDay[day].add(hour);
    }
  });

  // Get all valid days from events (ensure it's sorted in ascending order)
  const validDays = Object.keys(eventsByDay)
    .map(day => parseInt(day))
    .sort((a, b) => a - b);

  // Loop to schedule driving hours first
  while (drivingHourCount < drivingHours) {
    for (const day of validDays) {
      // If the current day is before the start day, skip it
      if (currentDay.day() > day) {
        continue;
      }

      // Set currentDay to the selected event day
      currentDay.day(day);

      for (const event of eventsByDay[day]) {
        if (drivingHourCount >= drivingHours) break;

        const newStartDate = moment(currentDay).hour(moment(event.startDate).hour()).minute(moment(event.startDate).minute());
        const newEndDate = moment(currentDay).hour(moment(event.endDate).hour()).minute(moment(event.endDate).minute());

        const newEvent = {
          ...event,
          start: newStartDate.toISOString(),
          end: newEndDate.toISOString(),
          startDate: newStartDate.toDate(),
          endDate: newEndDate.toDate(),
          ClassType: firstInstructor,
        };

        scheduledEvents.push(newEvent);
        drivingHourCount++;
        lastDrivingEventEndDate = newEndDate;
      }

      if (drivingHourCount >= drivingHours) break;
    }

    if (drivingHourCount < drivingHours) {
      // Not enough hours in the current week, go to the next week's Monday
      currentDay = currentDay.startOf('week').add(1, 'week').day(1); // Move to next Monday
    }
  }

  return scheduledEvents;
};



const secondInstructorSchedule = () => {
  console.log("Second instructor schedule called for:", );
  // Add your logic here
};

const thirdInstructorSchedule = () => {
  console.log("Third instructor schedule called for:", );
  // Add your logic here
};

function AssignScheduleTimetable({ Events, setEvents, Edit, Resources, studentSchedule, CoursePackage, StudentEnrollmentSelectedTimeTable }) {
  console.log("Course Package in the assign schedule timetable is:", CoursePackage)
  console.log("studentSchedule in the assign schedule timetable is:", Events)
  console.log("Resources in the assign schedule timetable is:", Resources)
  const [uniqueKey, setUniqueKey] = useState(0); // Start with an initial value
  const [StudentTimeTable, setStudentTimeTable] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null); // Initialize with current date
  const [instructorTypeExtracted, setInstructorTypeExtracted] = useState(null);
  const [secondInstructor, setSecondInstructor] = useState(null);
  const [thirdInstructor, setThirdInstructor] = useState(null);
  const [firstClassHourType, setFirstClassHourType] = useState(null);
  const [firstClassTotalHours, setFirstClassTotalHours] = useState(null);


  // Effect to extract instructor types and set state
useEffect(() => {
  if (Resources && Resources.length > 0) {
    const dataSource = Resources[0].dataSource; // Assuming you're checking the first resource's dataSource

    if (dataSource && dataSource.length > 0) {
      if (dataSource[0]) {
        setInstructorTypeExtracted(dataSource[0].InstructorType);
      }
      if (dataSource[1]) {
        setInstructorTypeExtracted(dataSource[1].InstructorType);
      }
      if (dataSource[2]) {
        setInstructorTypeExtracted(dataSource[2].InstructorType);
      }
    }
  }
}, [Resources]);

// Separate Effect to handle scheduling when instructor and class hours are ready
useEffect(() => {
  if (instructorTypeExtracted && CoursePackage && Events.length > 0) {
    let totalHours;

    // Set total hours based on the first instructor's type
    if (instructorTypeExtracted === 'Driving') {
      totalHours = CoursePackage.DrivingHours;
      setFirstClassHourType('Driving');
    } else if (instructorTypeExtracted === 'Online') {
      totalHours = CoursePackage.OnlineHours;
      setFirstClassHourType('Online');
    } else if (instructorTypeExtracted === 'InClass') {
      totalHours = CoursePackage.InClassHours;
      setFirstClassHourType('InClass');
    }

    // Now that we have the hours and instructor, schedule the events
    if (totalHours) {
      const scheduledEvents = firstInstructorSchedule(Events, totalHours, instructorTypeExtracted, selectedDate);
      console.log("Generated schedule for first instructor:", scheduledEvents);

      // Update events and state
      setStudentTimeTable((prevEvents) => {
        const updatedEvents = [...scheduledEvents];
        console.log("Updated Events State:", updatedEvents);
        return updatedEvents;
      });
      // if (Array.isArray(StudentEnrollmentSelectedTimeTable) && StudentEnrollmentSelectedTimeTable.length > 0) {
      //   const enrollmentEvents = [...StudentEnrollmentSelectedTimeTable];
      //   const updatedScheduledEvents = [...scheduledEvents];
      //   setStudentTimeTable([...enrollmentEvents, ...updatedScheduledEvents]);
      // }

      // setEvents((prevEvents) => {
      //   // Update the Events state with both enrollment and scheduled events
      //   const updatedEvents = [...StudentEnrollmentSelectedTimeTable, ...scheduledEvents];
      //   console.log("Updated Events State after schedule:", updatedEvents);
      //   return updatedEvents;
      // });
      

      setEvents([...scheduledEvents]);
      console.log("Updated Events State after schedule:", Events);
      setUniqueKey((prevKey) => prevKey + 1);
    }
  } 
  // else if (secondInstructor && CoursePackage && Events.length > 0) {
  //   let totalHours;

  //   // Set total hours based on the first instructor's type
  //   if (secondInstructor === 'Driving') {
  //     totalHours = CoursePackage.DrivingHours;
  //     setFirstClassHourType('Driving');
  //   } else if (secondInstructor === 'Online') {
  //     totalHours = CoursePackage.OnlineHours;
  //     setFirstClassHourType('Online');
  //   } else if (secondInstructor === 'InClass') {
  //     totalHours = CoursePackage.InClassHours;
  //     setFirstClassHourType('InClass');
  //   }

  //   // Now that we have the hours and instructor, schedule the events
  //   if (totalHours) {
  //     const scheduledEvents = firstInstructorSchedule(Events, totalHours, secondInstructor, selectedDate);
  //     console.log("Generated schedule for first instructor:", scheduledEvents);

  //     // Update events and state
  //     setStudentTimeTable((prevEvents) => {
  //       const updatedEvents = [...scheduledEvents];
  //       console.log("Updated Events State:", updatedEvents);
  //       return updatedEvents;
  //     });

  //     setEvents([...scheduledEvents]);
  //     console.log("Updated Events State after schedule:", Events);
  //     setUniqueKey((prevKey) => prevKey + 1);
  //   }
  // } else if (thirdInstructor && CoursePackage && Events.length > 0) {
  //   let totalHours;

  //   // Set total hours based on the first instructor's type
  //   if (thirdInstructor === 'Driving') {
  //     totalHours = CoursePackage.DrivingHours;
  //     setFirstClassHourType('Driving');
  //   } else if (thirdInstructor === 'Online') {
  //     totalHours = CoursePackage.OnlineHours;
  //     setFirstClassHourType('Online');
  //   } else if (thirdInstructor === 'InClass') {
  //     totalHours = CoursePackage.InClassHours;
  //     setFirstClassHourType('InClass');
  //   }

  //   // Now that we have the hours and instructor, schedule the events
  //   if (totalHours) {
  //     const scheduledEvents = firstInstructorSchedule(Events, totalHours, thirdInstructor, selectedDate);
  //     console.log("Generated schedule for first instructor:", scheduledEvents);

  //     // Update events and state
  //     setStudentTimeTable((prevEvents) => {
  //       const updatedEvents = [...scheduledEvents];
  //       console.log("Updated Events State:", updatedEvents);
  //       return updatedEvents;
  //     });

  //     setEvents([...scheduledEvents]);
  //     console.log("Updated Events State after schedule:", Events);
  //     setUniqueKey((prevKey) => prevKey + 1);
  //   }
  // } 
}, [instructorTypeExtracted, CoursePackage, Events, selectedDate]); // Add all dependencies


  useEffect(() => {
    setStudentTimeTable([...Events]);
  }, [Events]);
  

  async function getInstructorType(id) {
    console.log("Resources:", Resources);
    console.log("Resources[0].dataSource:", Resources?.[0]?.dataSource);
    console.log("id", id)

    //check whether the resources and id are present or not
    if (!id) {
      console.warn("Invalid ID passed to getInstructorType:", id);
      return null;
    }
  
    // Verify Resources structure
    if (!Resources || !Array.isArray(Resources)) {
      console.warn("Invalid Resources structure:", Resources);
      return null;
    }

    // Iterate through all dataSource arrays in Resources instead of just checking the first record
  for (let resource of Resources) {
    if (resource.dataSource && Array.isArray(resource.dataSource)) {
      const instructor = resource.dataSource.find((val) => val.InstructorFK === id);

      if (instructor) {
        console.log("Instructor found:", instructor);
        return instructor.InstructorType; // Or return the instructor object if needed
      }
    }
  }

  console.warn("No instructor found for ID:", id);
  return null;

   /*  const instructor = Resources?.[0]?.dataSource.find((val) => val.InstructorFK === id);
    console.log("instructor", instructor)
    console.log("instructor.InstructorType", instructor.InstructorType)
    return instructor ? instructor.InstructorType : null; */
  }
  

  const {setLastFiveValues, lastFiveValues} = useAuthContext()
  console.log("resources", Resources)
  const neww = Resources?.[0].dataSource.map((val)=> console.log("val", val))
  console.log("neww",neww)


  const renderTimeCell = (data) => (
    <i style={{ color: "green" }}>{data?.text}</i>
  );

  const OnUpdate = (e) => {
    if(studentSchedule === 'lastFiveValues') {
      const lastFive = Events.slice(-5);
      setLastFiveValues(lastFive);
    }

  };
  // console.log("lastFiveValues",lastFiveValues)
  

  const onAppointmentDeleting = (e) => {
    // console.log(e)
  };
  const onAppointmentDeleted = (e) => {
    // let Index = Events.indexOf(e?.appointmentData);
    // let NewEvents = Events.splice(Index, 1)

    setStudentTimeTable([...Events]);
    setEvents([...Events])
  };
  const onAppointmentAdding = (e) => {
  };
  const onAppointmentAdded = async (e) => {
    const { appointmentData } = e;
    console.log("appointment data", appointmentData)
    console.log("appoin", appointmentData.Instructors)

    const type = await getInstructorType(appointmentData.Instructors)

    // console.log("appointmentData", appointmentData)
    const NewEvents = Events.map((value) => {
      if (
        value.endDate === appointmentData.endDate &&
        value.startDate === appointmentData.startDate &&
        value.startDate === appointmentData.startDate &&
        value.description === appointmentData.description &&
        value.text === appointmentData.text
      ) {
        value.InstructorFK = appointmentData.Instructors;
        // value.ClassType = appointmentData.ClassType;
        value.ClassType =  type
      }
      return value;
    });
    setStudentTimeTable([...NewEvents]);
    setEvents([...NewEvents])
  };

  const onContentReady = (e) => {
    const observer = new MutationObserver(() => {
      const cells = e.element.querySelectorAll('.dx-scheduler-date-table-cell');
      cells.forEach((cell) => {
        const dateStr = cell.getAttribute('data-date');
        const cellDate = new Date(dateStr);
        if (cellDate.getDay() === 0) { // 0 = Sunday
          cell.style.backgroundColor = 'darkgrey'; // Set background color for Sundays
        }
      });
    });
  
    observer.observe(e.element, { childList: true, subtree: true });
  };

   // Function to handle date selection by the user
   const handleDateChange = (date) => {
    // 'date' is passed directly from the DatePicker
    setSelectedDate(date);
  };
  
  console.log("Events", Events)

  const customizeAppointment = (e) => {
    // Customize the color based on conditions
    let appointmentColor = "#3377b2";
    // console.log('eeee', e.appointmentData)
   

    if (e?.appointmentData?.CourseEnrollment?.Status === "Hold") {
      appointmentColor = "#d9534f";
    } else if (e?.appointmentData?.ClassType === "Online") {
      appointmentColor = "#7f82f9";
    } else if (e?.appointmentData?.ClassType === "InClass") {
      appointmentColor = "#5456c7";
    }else if (e?.appointmentData?.ClassType === "Driving") {

      appointmentColor = "#a1a3ef";
    }
    
    return (
      <div
        className=""
        id="newDiv"
        style={{
          backgroundColor: appointmentColor,
          border: "0",
          margin: "0",
          width: "calc(100% + 10px)", // Adjust for left and right padding (e.g., 5px on each side)
          height: "calc(100% + 10px)",
        }}
      >
        <div className="flex h-full w-full items-center justify-center">
          <p className="mb-2 mr-2 text-[10px]">
            {" "}
            { e?.appointmentData?.CourseEnrollment?.User?.FirstName }
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
    <div style={{ marginBottom: '50px', height: '30px', width: '300px' }} >
      <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={selectedDate}
            onChange={handleDateChange}
            // onChange={(date) => {
            //   setSelectedDate(date);
            // }}
            renderInput={(params) => <TextField {...params} style={{ height: '30px', width: '300px' }} />}
          />
        </LocalizationProvider>
    </div>

      <div>
        <Scheduler
          key={uniqueKey}  // Use uniqueKey here to force re-render
          id="scheduler"
          dataSource={StudentTimeTable}
          currentView="week"
          views={["week", "month"]}
          startDayHour={9}
          endDayHour={19}
          onAppointmentUpdated={OnUpdate}
          onAppointmentDeleted={onAppointmentDeleted}
          onAppointmentDeleting={onAppointmentDeleting}
          onAppointmentAdding={onAppointmentAdding}
          onAppointmentAdded={onAppointmentAdded}
          showCurrentTimeIndicator={true}
          shadeUntilCurrentTime={true}
          appointmentRender={customizeAppointment}
          className=""
          
          // onContentReady={onContentReady} // Add the event handler here
        >
          <Editing allowDeleting={Edit} allowAdding={Edit} allowUpdating={Edit} />
          <View
            type="week"
            cellDuration={45}
            dateCellRender={renderTimeCell}
          />
          {/* {Resources?.length > 0
            ? Resources.map((value) => (
                <Resource
                  key={value.fieldExpr}
                  fieldExpr={value.fieldExpr}
                  dataSource={value.dataSource}
                />
              ))
            : null} */}
            {Resources?.length > 0 ? <Resource
                  key={Resources[0].fieldExpr}
                  fieldExpr={Resources[0].fieldExpr}
                  dataSource={Resources[0].dataSource}
                  style={{ width: '100%' }} 
                />
            
            : null}
        </Scheduler>
      </div>
    </>
  );
}

export default AssignScheduleTimetable;

// [
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T04:00:00.000Z",
//     "endDate": "2024-06-24T04:30:00.000Z",
//     "Instructors": "66a862b2-86a2-4d5c-9353-f5709962e364",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   },
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T05:30:00.000Z",
//     "endDate": "2024-06-24T06:00:00.000Z",
//     "Instructors": "d0b3c6c1-2114-4c4a-a36c-37207abf5ae8",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   },
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T06:00:00.000Z",
//     "endDate": "2024-06-24T06:30:00.000Z",
//     "Instructors": "d0b3c6c1-2114-4c4a-a36c-37207abf5ae8",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   },
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T06:30:00.000Z",
//     "endDate": "2024-06-24T07:00:00.000Z",
//     "Instructors": "4b716892-63d8-47cf-815c-19d6f3bca83f",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   }
// ]



// [
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T04:00:00.000Z",
//     "endDate": "2024-06-24T04:30:00.000Z",
//     "Instructors": "4b716892-63d8-47cf-815c-19d6f3bca83f",
//     "ClassType": "Driving",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   },
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T05:00:00.000Z",
//     "endDate": "2024-06-24T05:30:00.000Z",
//     "Instructors": "d0b3c6c1-2114-4c4a-a36c-37207abf5ae8",
//     "ClassType": "Online",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   },
//   {
//     "allDay": false,
//     "startDate": "2024-06-24T06:00:00.000Z",
//     "endDate": "2024-06-24T06:30:00.000Z",
//     "Instructors": "e57d1ea5-8bef-4fb1-a484-39beac9ceec5",
//     "ClassType": "InClass",
//     "text": "",
//     "EnrollmentFK": "bc2d4b41-c88c-4ba8-b11f-65eba3f18bf1"
//   }
// ]