import { CheckSchedule } from "../../Payment/Components/Helper/CheckSchedule"
import { DaysName } from "./Components/DaysName"
import { RadioBtns } from "./Components/RadioBtns"
import { CheckboxBtns } from "./Components/CheckBoxBtns"
import { ClassesCheckboxBtns } from "./Components/ClassesCheckboxBtns"
import { TimeSlotsNames } from "./Components/TimeSlotsName"

// export const DaysAndTimeSlot = ({ Days, TimeIndex, TimeSlot, BorderStyle, TimeArr, SetSchedule, Err, setErr, EnrollmentData, classHoursData, isClassHours, FirstTimeAttempted }) => {
//     const Change = (DayName, TimeSlotChecked) => {

//         SetSchedule(DayName, TimeSlotChecked)
//         if(isClassHours){
//             CheckSchedule(classHoursData, Err, setErr, DayName, FirstTimeAttempted)
//         } else {
//             CheckSchedule(EnrollmentData, Err, setErr, DayName, FirstTimeAttempted)
//         }
       
//     }

//     return Days.map((day, DaysIndex) => {

//          // Get the count of selected time slots for the current day
//          const selectedSlotsCount = EnrollmentData.StudentData.Schedule[Days[TimeIndex]]?.length || 0;

//         // This would Display Time Slots
//         if (TimeIndex === 0) {
//             return (<TimeSlotsNames key={`${day} + ${TimeSlot}`} BorderStyle={BorderStyle} TimeArr={TimeArr} DaysIndex={DaysIndex} />)
//         }

//         //This would diplay days of the week
//         if (DaysIndex === 0) {
//             return (<DaysName key={`${day} + ${TimeSlot}`} day={day} Days={Days} TimeSlot={TimeSlot} BorderStyle={BorderStyle} DaysIndex={DaysIndex} TimeIndex={TimeIndex} Err={Err} />)
//         }

//         //This would display radio buttons
//         // return (<RadioBtns key={`${day} + ${TimeSlot}`} Days={Days} BorderStyle={BorderStyle} TimeIndex={TimeIndex} Change={Change} EnrollmentData={EnrollmentData} DaysIndex={DaysIndex} TimeArr={TimeArr} />)
//         return (
//             <>
//             if(isClassHours){
//                 <CheckboxBtns 
//                     key={`${day} + ${TimeSlot}`}
//                     Days={Days} 
//                     BorderStyle={BorderStyle} 
//                     TimeIndex={TimeIndex} 
//                     Change={Change} 
//                     EnrollmentData={EnrollmentData} 
//                     DaysIndex={DaysIndex} 
//                     TimeArr={TimeArr}
//                     selectedSlotsCount={selectedSlotsCount} />
//                 } else {
//                 <CheckboxBtns 
//                     key={`${day} + ${TimeSlot}`}
//                     Days={Days} 
//                     BorderStyle={BorderStyle} 
//                     TimeIndex={TimeIndex} 
//                     Change={Change} 
//                     EnrollmentData={EnrollmentData} 
//                     DaysIndex={DaysIndex} 
//                     TimeArr={TimeArr}
//                     selectedSlotsCount={selectedSlotsCount} />
//                 } 
//                 </>
//                 )
//     })

// }

export const DaysAndTimeSlot = ({
    Days,
    TimeIndex,
    TimeSlot,
    BorderStyle,
    TimeArr,
    SetSchedule,
    Err,
    setErr,
    EnrollmentData,
    classHoursData,
    isClassHours,
    FirstTimeAttempted
  }) => {
    const Change = (DayName, TimeSlotChecked) => {
      SetSchedule(DayName, TimeSlotChecked);
      if (isClassHours) {
        CheckSchedule(classHoursData, Err, setErr, DayName, FirstTimeAttempted);
      } else {
        CheckSchedule(EnrollmentData, Err, setErr, DayName, FirstTimeAttempted);
      }
    };
  
    return Days.map((day, DaysIndex) => {
      // Determine which data to use based on `isClassHours`
      const currentSchedule = isClassHours
        ? classHoursData?.DayandHoursData?.Schedule
        : EnrollmentData?.StudentData?.Schedule;
  
      // Get the count of selected time slots for the current day (only used for EnrollmentData)
      const selectedSlotsCount = currentSchedule[Days[TimeIndex]]?.length || 0;
  
      // This would Display Time Slots
      if (TimeIndex === 0) {
        return (
          <TimeSlotsNames
            key={`${day} + ${TimeSlot}`}
            BorderStyle={BorderStyle}
            TimeArr={TimeArr}
            DaysIndex={DaysIndex}
          />
        );
      }
  
      // This would display days of the week
      if (DaysIndex === 0) {
        return (
          <DaysName
            key={`${day} + ${TimeSlot}`}
            day={day}
            Days={Days}
            TimeSlot={TimeSlot}
            BorderStyle={BorderStyle}
            DaysIndex={DaysIndex}
            TimeIndex={TimeIndex}
            Err={Err}
          />
        );
      }
  
      // This would display checkbox buttons depending on the context (enrollment or class hours)
      return (
        <>
          {isClassHours ? (
            <ClassesCheckboxBtns
              key={`${day} + ${TimeSlot}`}
              Days={Days}
              BorderStyle={BorderStyle}
              TimeIndex={TimeIndex}
              Change={Change}
              classHoursData={classHoursData}
              DaysIndex={DaysIndex}
              TimeArr={TimeArr}
            />
          ) : (
            <CheckboxBtns
              key={`${day} + ${TimeSlot}`}
              Days={Days}
              BorderStyle={BorderStyle}
              TimeIndex={TimeIndex}
              Change={Change}
              EnrollmentData={EnrollmentData}
              DaysIndex={DaysIndex}
              TimeArr={TimeArr}
              selectedSlotsCount={selectedSlotsCount} // Only relevant for EnrollmentData
            />
          )}
        </>
      );
    });
  };
  



