import React, { useEffect, useState } from "react";
import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  Polyline,
} from "@react-google-maps/api";

const CustomeGoogleMap = (props) => {
  const {
    directionsRes,
    center,
    onLoadMap,
    zoom,
    currentPosition,
    currentRoute,
  } = props;

  return (
    <>
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: 800 }}
        center={center}
        onLoad={onLoadMap}
        zoom={12}
      >
        {directionsRes != null ? (
          <DirectionsRenderer directions={directionsRes} />
        ) : null}
        {currentRoute.length > 0 && (
          <Polyline path={currentRoute} options={{ strokeColor: "#36454F" }} />
        )}
        {currentPosition && <Marker position={currentPosition} />}
      </GoogleMap>
    </>
  );
};

export default CustomeGoogleMap;
