import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  TextField,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ClassSummaryDialog = ({ open, handleClose, onDelivered }) => {
  return (
    <Dialog open={open} aria-labelledby="dialog-title">
      <DialogTitle id="dialog-title" className="relative w-full">
        <Typography sx={{ fontWeight: 700 }} className="w-full text-center">
          Class Summary
        </Typography>
        <Box className="absolute top-0 right-0">
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          sx={{ fontWeight: 700 }}
          className="w-full text-center"
        >
          Please provide a short summary of this class
        </Typography>
        <div className="mt-4 flex justify-center">
          <img
            src="/assets/complete-class-popup-img.png"
            alt="Class Summary"
            className="rounded"
            width={150}
            height={150}
          />
        </div>
        <TextField
          variant="outlined"
          multiline
          rows={4}
          // value={}
          // onChange={handleSummaryChange}
          placeholder="The instructor can provide a short brief about what he taught his students in today's lesson."
          fullWidth
          className="!mt-4"
        />
      </DialogContent>
      <DialogActions className="flex justify-center">
        <Button
          variant="outlined"
          className="border-blue-500 text-blue-500 mr-2"
          onClick={handleClose}
        >
          Aborted
        </Button>
        <Button
          variant="contained"
          className="bg-blue-500 text-white"
          onClick={onDelivered}
        >
          Completed
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClassSummaryDialog;
