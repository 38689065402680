import React, { useEffect, useRef, useState } from "react";
import { InputWithImage } from "../../../../../../../Components/InputComps/InputComps";
import { CheckString } from "../../../../../../../Helpers/TypeChecker/CheckString";
import { Country, State } from "country-state-city";


let autoComplete;

const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (
                script.readyState === "loaded" ||
                script.readyState === "complete"
            ) {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
};

const SearchLocationByInputStudent = ({
    setErr,
    setInstructorData,
    InstructorData,
    Err,
    readOnly,
    updateOnly,
    setSelectedCoordinates,
    EnrollmentData,
    newCountryPostalCode,
    Id,
}) => {
    console.log("newCountryPostalCode in serach location is", newCountryPostalCode)
    const [query, setQuery] = useState("");
    console.log("query", query)
    const autoCompleteRef = useRef(null);

    const getCountryCode = (countryName) => {
        const selectedCountry = Country.getAllCountries().find(
            (country) => country.name === countryName
        );

        if (selectedCountry) {
            return selectedCountry.isoCode.toLowerCase();
        }
        return "";
    };

    const handleScriptLoad = (updateQuery, autoCompleteRef) => {
        console.log("EnrollmentData?.StudentData?.Country",EnrollmentData?.StudentData?.Country)
        const country = EnrollmentData?.StudentData?.Country || newCountryPostalCode || "";
        console.log("Country in API maps is:", country);
       const countryCode = getCountryCode(country);


       //limiting api to display only those places that are present in the country selected by the user
        const componentRestrictions = countryCode
            ? { country: countryCode }
            : {};
            
        autoComplete = new window.google.maps.places.Autocomplete(
            autoCompleteRef.current,
            {
                // types: ["(cities)"],
                // componentRestrictions: { country: "IN" },
                componentRestrictions
            }
        );

        autoComplete.addListener("place_changed", () => {
            handlePlaceSelect(updateQuery);
        });
    };
    console.log("AddressErr", Err);
    console.log("readOnly", readOnly);
    const handlePlaceSelect = async (updateQuery) => {
        const addressObject = await autoComplete.getPlace();

        if (!addressObject || !addressObject.formatted_address) {
            // No valid address found, set error and clear instructor data
            setErr({ Address: "No valid address found" });
            setInstructorData({ Address: "" }); // Clear instructor data
            return;
        }

        const query = addressObject.formatted_address;
        updateQuery(query);
        console.log({ query });

        const latLng = {
            lat: addressObject?.geometry?.location?.lat(),
            lng: addressObject?.geometry?.location?.lng(),
        };
        // if (query.trim().length === 0) {
        //     setErr({ Address: "Address* is required" });
        //     // return;
        // }
        console.log({ latLng });
        setSelectedCoordinates(latLng);
        setInstructorData((prevData) => ({
            ...prevData,
            Address: query,
        }));
    };

    // const apiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
    const apiKey = "AIzaSyCWiF-rhJC5vG-_6akKZbSiJ1AIRxJe_mI";
   /*  useEffect(() => {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`,
            () => handleScriptLoad(setQuery, autoCompleteRef)
        );
    }, []); */
    useEffect(() => {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`,
            () => handleScriptLoad(setQuery, autoCompleteRef)
        );
    }, [EnrollmentData?.StudentData?.Country, EnrollmentData?.StudentData?.Province, EnrollmentData?.StudentData?.City, newCountryPostalCode]);
    
    const AddParentStyle = (e) =>
        (e.target.parentNode.style = "border:2px solid black");
    const RemoveParentStyle = (e) => (e.target.parentNode.style = "");
    const ResponsiveInpuyStyle = "text-5xs md:text-4xs lg:text-3xs";
    const Container = "w-full  flex flex-col  ";
    const TextStyle = "text-3xs md:text-2xs xl:text-xs";
    const handleQuery = (e) => {
        e.preventDefault();
        setQuery(e.target.value);
        const inputValue = e.target.value;
        setQuery(inputValue);

        // if (inputValue === "") {
        //     setInstructorData({ Address: "" });
        //     setQuery("");
        // } else {
        //     setInstructorData({ Address: inputValue });
        //     setQuery(inputValue);
        // }
        setErr(null);
    };

    return (
        <div className={Container}>
            <label htmlFor={Id} className={TextStyle}>
                {"Address *"}
            </label>

            <h4 className="text-right text-4xs font-normal text-[red]">
                {Err && query === "" ? "Address * is required" : null}
            </h4>

            {/* <span className="flex w-full gap-4 rounded-md border-[1px] border-solid border-[#E2E2E2] bg-white py-2 px-4 shadow-[4px_5px_6px_#00000029]"> */}
            <span className="flex w-full gap-4 rounded-md border-[1px] border-solid border-[#E2E2E2] bg-white py-2 px-4 shadow-[4px_5px_6px_#00000029]">
                <img
                    src={require("../../Assets/Location.svg").default}
                    className="h-8"
                    alt=""
                />
                <input
                    ref={autoCompleteRef}
                    Id={Id}
                    type="text"
                    disabled={readOnly}
                    placeholder={"house no. street name city province...."}
                    className={`none w-full border-none shadow-none outline-none  ${ResponsiveInpuyStyle}`}
                    onFocus={AddParentStyle}
                    onBlur={RemoveParentStyle}
                    value={
                        updateOnly || readOnly
                            ? query || InstructorData?.Address
                            : query
                    }
                    // value={query}
                    onChange={handleQuery}
                />
            </span>
            {/* </div> */}
            {/* <input
                    ref={autoCompleteRef}
                    className=""
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder="Search Places ..."
                    value={query}
                /> */}
        </div>
    );
};

export default SearchLocationByInputStudent;
