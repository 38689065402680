// import React from 'react'
// import { useState } from 'react'

// const Packageslist = ({ PackagesArr, EnrollmentData, setEnrollmentData, Err, setErr }) => {
//     const [SelectedElement, setSelectedElement] = useState();

//     const SelectPackage = (e, Package) => {
//         if (SelectedElement !== e.target) {
//             let Btns = document.querySelectorAll('.SelectButton')

//             for (let index = 0; index < Btns.length; index++) {
//                 if (e.target !== Btns[index]) {
//                     Btns[index].style.backgroundColor = "#A1A3EF";
//                     Btns[index].innerText = "Select"
//                 } else {
//                     if (Btns[index].innerText === "Selected") {
//                         Btns[index].innerText = "Select"
//                         Btns[index].style.backgroundColor = "#A1A3EF";
//                     }
//                     else {
//                         Btns[index].style.backgroundColor = "#1c1d36";
//                         Btns[index].innerText = "Selected"
//                     }
//                 }
//             }

//             setEnrollmentData({ ...EnrollmentData, Package: Package })
//             setSelectedElement(e.target)
//             if (Err?.Package) {
//                 delete Err?.Package
//                 setErr({ ...Err })
//             }
//         }
//     }

//     return (
//         <div className='flex flex-wrap justify-center flex-col md:flex-row gap-x-14 gap-y-7 py-28 px-2 sm:px-3 w-full lg:w-11/22 2xl:w-4/5' >

//             {PackagesArr?.map((value, index) =>
//                 <div key={value?.CoursePackageId} className='flex gap-2 md:gap-4 w-full md:w-[46%] lg:w-2/5 xl:w-[45%] 2xl:w-[47%] '>
//                     <span className={`bg-white border-[#E8E8E8] border-[2px] border-solid shadow-[0px_3px_6px_#00000046] rounded-lg px-5 flex items-center w-4/5 min-w-[250px] max-w-[556px]`}
//                     >

//                         <p className='text-6xs sm:text-3xs md:text-4xs lg:text-2xs xl:text-xs 2xl:text-[24px]'>

//                             {value?.DrivingHours ? `${value?.DrivingHours} Hours Driving` : null}
//                             {value?.InClassHours ? ` + ${value?.InClassHours} Hours Inclass` : null}
//                             {value?.InClassHours ? ` + ${value?.OnlineHours} Hours Online ` : null}
//                             - ${value.TotalFee} + GST

//                         </p>

//                     </span>

//                     <button type='button'
//                         className={`border-none bg-[#A1A3EF] text-white rounded-2xl cursor-pointer
//                         text-4xs    sm:text-2xs     md:text-3xs     lg:text-2xs    xl:text-xs     2xl:text-base
//                         py-3        sm:py-[14px]    md:py-2         lg:py-[14px]
//                         px-5                        md:px-3         lg:px-5
//                         SelectButton
//                         `}
//                         onClick={(e) => { SelectPackage(e, value) }}
//                     >
//                         Select
//                     </button>

//                 </div>
//             )
//             }
//         </div>
//     )
// }

// export default Packageslist

import React from "react";
import { useState } from "react";
import discountImage from "./Assets/discount.svg";
import taxFreeImage from "./Assets/taxfree.svg";
import MostPurchased from "./Assets/mostpurchased.svg";
import taxFreeMostPurchased from "./Assets/taxfreeMostPur.svg";
import discountMostPurchased from "./Assets/discountedMostPur.svg";
import { calculateDiscount } from "../Helpers/calculateDiscount";

const Packageslist = ({
  EnrollmentData,
  setEnrollmentData,
  Err,
  setErr,
  PackagesArr,
}) => {
  const [SelectedElement, setSelectedElement] = useState();
  const [packagee, setPackage] = useState([1, 2, 3, 4, 5, 6, 7]);

  console.log("course packages==>", PackagesArr);
  const SelectPackage = (e, Package) => {
    if (SelectedElement !== e.target) {
      let Btns = document.querySelectorAll(".SelectButton");

      for (let index = 0; index < Btns.length; index++) {
        if (e.target !== Btns[index]) {
          Btns[index].style.backgroundColor = "#ea52c6";
          Btns[index].innerText = "Select Package";
        } else {
          if (Btns[index].innerText === "Selected") {
            Btns[index].innerText = "Select Package";
            Btns[index].style.backgroundColor = "#ea52c6";
          } else {
            Btns[index].style.backgroundColor = "#1c1d36";
            Btns[index].innerText = "Selected";
          }
        }
      }

      setEnrollmentData({ ...EnrollmentData, Package: Package });
      setSelectedElement(e.target);
      if (Err?.Package) {
        delete Err?.Package;
        setErr({ ...Err });
      }
    }
  };

  const cardCssOne = `flex text-white gap-3`;

  return (
    <div className="lg:w-11/22 flex w-full flex-col flex-wrap content-center justify-center gap-x-14 gap-y-10 py-10 px-4 sm:px-3 md:flex-row 2xl:w-4/5">
      {PackagesArr &&
        PackagesArr.map((Val, index) => {
          let isMostPurchased;
          let isDiscounted;
          let isTaxFree;
          let isTaxFreeIsMostPurchased;
          let isDiscountedIsMostPurchased;
          isMostPurchased = Val?.SellCount > 9;
          if (Val?.DiscountType === "add_discount" && isMostPurchased) {
            isDiscountedIsMostPurchased = true;
          } else if (Val?.DiscountType === "add_discount") {
            isDiscounted = true;
          }

          if (Val?.DiscountType === "no_tax" && isMostPurchased) {
            isTaxFreeIsMostPurchased = true;
          } else if (Val?.DiscountType === "no_tax") {
            isTaxFree = true;
          }

          // let discountedPrice;
          // let discountAmount;
          // if(isDiscounted) {
          //   const discountPercentage = parseFloat(Val?.AddDiscount) || 0;
          //   console.log("discountPercentage", discountPercentage)

          //   // Calculate the discount amount
          //    discountAmount = (Val?.TotalFee * discountPercentage) / 100;

          //   // Calculate the discounted price
          //    discountedPrice = Val?.TotalFee - discountAmount;

          // }

          let discountInfo;
          if (isDiscounted) {
            const discountPercentage = parseFloat(Val?.AddDiscount) || 0;
            discountInfo = calculateDiscount(Val?.TotalFee, discountPercentage);
          }

          return (
            <>
              <div className="main-card relative h-[560px] w-[360px] rounded-2xl bg-[#410F85]">
                {isMostPurchased && (
                  <>
                    <div className="absolute top-[-17px] right-[-19px]">
                      <div className="relative">
                        <p className="absolute top-[44px] right-[2px] rotate-45 text-[15px] font-semibold tracking-widest text-white">
                          Most Purchased
                        </p>
                      </div>
                      <img
                        className="w-[150px]"
                        src={MostPurchased}
                        alt="MostPurchased"
                      />
                    </div>
                  </>
                )}

                {isDiscountedIsMostPurchased && (
                  <>
                    <div className="absolute top-[-17px] right-[-19px]">
                      <div className="relative">
                        <p className="absolute top-[35px] right-[-7px] rotate-45 text-center text-[15px] font-semibold tracking-widest text-white">
                          Discounted <br /> Most Purchased
                        </p>
                      </div>
                      <img
                        className="w-[150px]"
                        src={discountMostPurchased}
                        alt=""
                      />
                    </div>
                  </>
                )}

                {isDiscounted && (
                  <div className="absolute top-[-17px] right-[-19px]">
                    <div className="relative">
                      <p className="absolute top-[44px] right-[7px] rotate-45 text-[18px] font-semibold tracking-widest text-white">
                        Discounted
                      </p>
                    </div>
                    <img className="w-[150px]" src={discountImage} alt="" />
                  </div>
                )}

                {isTaxFreeIsMostPurchased && (
                  <>
                    <div className="absolute top-[-17px] right-[-19px]">
                      <div className="relative">
                        <p className="absolute top-[35px] right-[-7px] rotate-45 text-center text-[15px] font-semibold tracking-widest text-white">
                          Tax Free <br /> Most Purchased
                        </p>
                      </div>
                      <img
                        className="w-[150px]"
                        src={taxFreeMostPurchased}
                        alt=""
                      />
                    </div>
                  </>
                )}

                {isTaxFree && (
                  <>
                    <div className="absolute top-[-17px] right-[-19px]">
                      <div className="relative">
                        <p className="absolute top-[44px] right-[19px] rotate-45 text-[18px] font-semibold tracking-widest text-white">
                          Tax Free
                        </p>
                      </div>
                      <img
                        className="w-[150px]"
                        src={taxFreeImage}
                        alt="taxFreeImage"
                      />
                    </div>
                  </>
                )}

                <div className="card-top rounded-t-2xl bg-[#4C07AB] py-5 text-white">
                  <div className="flex flex-col items-center justify-center gap-5">
                    <h2 className="max-w-[200px] text-center text-3xs md:text-xs">
                      {Val?.PackageName ? Val?.PackageName : ""}
                    </h2>
                    <p className="">
                      {" "}
                      {Val?.DiscountType === "add_discount" && (
                        <span className="px-5 text-xs line-through decoration-[#ea52c6] decoration-2 md:text-sm ">
                          ${Val?.TotalFee}
                        </span>
                      )}{" "}
                      {isDiscounted ? (
                        <span className="text-xs md:text-sm">
                          {`$${discountInfo?.discountedPrice}`}<sup className="text-7xs text-start relative -top-1.5">+Tax</sup>
                        </span>
                      ) : (
                        <span className="text-xs md:text-sm">{`$${Val?.TotalFee}`}{!isTaxFree ?<sup className="text-7xs text-start relative -top-1.5">+Tax</sup> : null}</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className=" rounded-b-2xl bg-[#410F85] px-3 py-5">
                  <div className="card-bottom flex flex-col gap-5 px-8 py-3">
                    <div>
                      <div className={`${cardCssOne}`}>
                        <span class="material-symbols-outlined">
                          check_circle
                        </span>
                        <p>{Val?.DrivingHours} Hours Driving</p>
                      </div>
                    </div>
                    <div>
                      <div className={`${cardCssOne}`}>
                        <span class="material-symbols-outlined">
                          check_circle
                        </span>
                        <p>{Val?.OnlineHours} Hours Online</p>
                      </div>
                    </div>
                    <div>
                      <div className={`${cardCssOne}`}>
                        <span class="material-symbols-outlined">
                          check_circle
                        </span>
                        <p>{Val?.InClassHours} Hours Inclass</p>
                      </div>
                    </div>
                    {Val?.DiscountType === "add_discount" && (
                      <>
                        <div>
                          <div className={`${cardCssOne}`}>
                            <span class="material-symbols-outlined">
                              check_circle
                            </span>
                            <p>{Val?.AddDiscount}% Off</p>
                          </div>
                        </div>
                      </>
                    )}
                    <div>
                      {Val?.Installments > 1 && (
                        <div className={`${cardCssOne}`}>
                          <span class="material-symbols-outlined text-white">
                            task_alt
                          </span>
                          <p>
                            The payment can be done in {Val?.Installments}{" "}
                            installments ( weekly, bimonthly )
                          </p>
                        </div>
                      )}
                    </div>

                    {!isTaxFree &&
                      <div>
                        <div className={`${cardCssOne}`}>
                          <span class="material-symbols-outlined text-white">
                            task_alt
                          </span>
                          <p>
                          Tax not included in displayed price
                          </p>
                        </div>
                    </div>
                    }
                  </div>
                </div>
                <div className="btn-div absolute bottom-0 flex w-full items-center justify-center pb-10 ">
                  <button
                    type="button"
                    className={`SelectButton cursor-pointer rounded-2xl border-none bg-[#ea52c6]
                        py-2    px-5     text-4xs     text-white    sm:py-[14px]     sm:text-2xs md:text-3xs         
                         lg:text-3xs         xl:text-xs    2xl:text-2xs
                        `}
                    onClick={(e) => {
                      SelectPackage(e, Val);
                    }}
                  >
                    Select Package
                  </button>
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
};

export default Packageslist;
