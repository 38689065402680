import React, { useEffect, useState } from "react";
import dayjs from 'dayjs';
import "./CourseEnrollment.css";
import EnrollmentSteps from "./EnrollmentSteps/EnrollmentSteps";
import Packages from "./Packages/Packages";
import Payment from "./Payment/Payment";
import TopPart from "./TopPart/TopPart";
import Footer from "../../Components/Footer/Footer";
import TimeSlots from "./TimeSlots/TimeSlots";
import useCheckLogin from "../../Helpers/CustomHooks/CheckLogin";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { SubmitForm } from "./Helpers";
import { usePageLoadCheckers } from "./Helpers/PageLoadCheckers";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import { ScrollSingleElement } from "../../Helpers/ScrolltoElement";
import UserInfo from "./UserInfo/UserInfo"

const CourseEnrollment = () => {
    // const UserInfo = "bilal"
    const location = useLocation();
    const Dispatch = useDispatch();
    // const WriteableUserInfo = JSON.parse(JSON.stringify(UserInfo))
    
    // console.log("vWriteableUserInfoStudentImage", WriteableUserInfo.StudentImage)
    console.log("location", location)
    const { UserInfo: UserInformation } = useSelector((Store) => Store.LoginSignupReducer);
    console.log("UserInforamtion", UserInformation)

    const [ShowTimeSlots, setShowTimeSlots] = useState(false);
    const [PaymentDetailPopup, setPaymentDetailPopup] = useState(false)
    const { loading, error } = useSelector((Store) => Store.CourseReducer);
    const [StudentImage, setStudentImage] = useState("");
    const [LatestLicense, setLatestLicense] = useState("");
    const [ShowSchedule, setShowSchedule] = useState(false)
    const [EnrollmentData, setEnrollmentData] = useState({
        StudentData: {
            FirstName: "",
            LastName: "",
            // Address: "",
            Country: "",
            PostalCode: "",
            Province: "",
            City: "",
            DOB: "",
            PhoneNumber: "",
            Email: "",
            Gender: "",
            TermsAndConditions: false,

            FreeHours: [
                        {
                            Start: { hh: "0", mm: "0", AM: "PM" },
                            End: { hh: "0", mm: "0", AM: "PM" },
                        },
                    ],
                    Schedule: {
                        Monday: undefined,
                        Tuesday: undefined,
                        Thursday: undefined,
                        Wednesday: undefined,
                        Friday: undefined,
                    },
        },
        Package: undefined,
    });

    const [Err, setErr] = useState({});
    console.log("ErrErr", Err);
    useEffect(() => {
        if (error?.response?.data) {
            setErr(error?.response?.data);
            if (error?.response?.data)
                for (const [key] of Object.entries(error?.response?.data)) {
                    if (key) {
                        ScrollSingleElement(key, 200);
                        break;
                    }
                }
        }
    }, [error]);
    // console.log("Errors", error);

    // console.log(EnrollmentData)
    useCheckLogin(true, ["Student"]);
    const Navigate = useNavigate();
    const PackageRef = useRef(null);
    usePageLoadCheckers(location, EnrollmentData, setEnrollmentData );
    console.log("EnrollmentData", EnrollmentData);
  

    console.log("EnrollmentErrors", Err);
    const [agreeTerms, setAgreeTerms] = useState("false");
    const [Addresses, setAddresses] = useState({
        Address: "",
    });
    useEffect(() => {
        if(UserInformation){
            setEnrollmentData((prevData) => ({
                ...prevData,
                StudentData: {
                    ...prevData.StudentData,
                    Address: UserInformation?.StudentInfo?.Address ? UserInformation.StudentInfo.Address : Addresses.Address,
                },
            }));
        }
    }, [Addresses, UserInformation]);

console.log("LatestLicense =>", LatestLicense)
console.log("StudentImage =>", StudentImage)
    // console.log("Address", Addresses);
    return !loading ? (
        <div className="mt-20">
            <TopPart />

            <TimeSlots
                ShowTimeSlots={ShowTimeSlots}
                setShowTimeSlots={setShowTimeSlots}
                setEnrollmentData={setEnrollmentData}
                EnrollmentData={EnrollmentData}
                Err={Err}
                setErr={setErr}
                setShowSchedule={setShowSchedule}
            />

            <EnrollmentSteps />

            <form
                onSubmit={(e) =>
                    SubmitForm(
                        e,
                        EnrollmentData,
                        Err,
                        setErr,
                        Dispatch,
                        Navigate
                    )
                }
            >
                <UserInfo
                ShowSchedule={ShowSchedule}
                    StudentImage={StudentImage}
                    setStudentImage={setStudentImage}
                    setEnrollmentData={setEnrollmentData}
                    EnrollmentData={EnrollmentData && EnrollmentData}
                    PackageRef={PackageRef}
                    Addresses={Addresses}
                    setAddresses={setAddresses}
                    Err={Err}
                    setErr={setErr}
                    setShowTimeSlots={setShowTimeSlots}
                    LatestLicense={LatestLicense}
                     setLatestLicense={setLatestLicense}
                />

                <Packages
                    setEnrollmentData={setEnrollmentData}
                    EnrollmentData={EnrollmentData}
                    PackageRef={PackageRef}
                    setErr={setErr}
                    Err={Err}
                />

                <Payment
                    StudentImageCheck={UserInformation && UserInformation?.StudentImage ? true : false}
                    Package={EnrollmentData?.Package}
                    EnrollmentData={EnrollmentData}
                    StudentImage={StudentImage}
                    LatestLicense={LatestLicense}
                    Err={Err}
                    setErr={setErr}
                    Addresses={Addresses}
                    UserInformation={UserInformation}
                    PaymentDetailPopup={PaymentDetailPopup}
                    setPaymentDetailPopup={setPaymentDetailPopup}
                />
            </form>
            <Footer FooterBgColor="#F6F5F5" />
        </div>
    ) : (
        <LoadingSpinner />
    );
};

export default CourseEnrollment;
