import React, { useRef, useState, useEffect } from "react";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import { Button } from "@mui/material";
import Webcam from "react-webcam";
import PickUpModal from "./PickUpModal";
import { useParams, useNavigate } from "react-router-dom";
import CustomeGoogleMap from "./GoogleMap";
import { useMap } from "./GoogleMap/mapHook";
import InfoSummer from "./GoogleMap/InfoSummer.jsx";
import {
  getCurrentLocation,
  convertDistanceToMeters,
} from "./GoogleMap/utils.js";
import OTPModal from "./OTPModal.jsx";
import ClassSummaryDialog from "./ClassSummaryDialog.jsx";
import { wayPointByRoad } from "../../Helpers/utils/googleMap.js";
import { getSocket } from "./socket.js";

const google = window.google;
const defaultCenter = { lat: 43.65323, lng: -79.38318 };
const defaultLocations = {
  origin: { address: "", latlng: null },
  destination: { address: "", latlng: null },
};
const defaultZoom = 12;

const StartClass = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [streams, setStreams] = useState();
  const [openCamera, setOpenCamera] = useState(false);
  const [openOTPModal, setOpenOTPModal] = useState(false);
  const [classSummaryDialog, setClassSummaryDialog] = useState(false);
  // map starts
  const [directionsRes, setDirectionsRes] = useState(null);
  const [map, setMap] = useState(/** @type google.maps.map */ (null));
  const [mapZoom, setMapZoom] = useState(defaultZoom);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [locations, setLocations] = useState(defaultLocations);
  const [isTrainingStart, setIsTrainingStart] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [currentRoute, setCurrentRoute] = useState([]);

  const mapObj = useMap();
  const videoRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const socket = getSocket();

  useEffect(() => {
    if (
      !isTrainingStart &&
      locations.origin.address &&
      locations.destination.address
    ) {
      handleMapDirections();
    }
  }, [map, locations]);

  useEffect(() => {
    if (!isTrainingStart && distance) {
      let distanceInMeter = convertDistanceToMeters(distance);
      if (distanceInMeter <= 1000) {
        handleCamera();
      }
    }
  }, [distance]);

  useEffect(() => {
    const getLocations = async () => {
      const instrutorAddress = await getCurrentLocation("instructor");
      const studentAddress = await getCurrentLocation("student");
      setLocations((oldLat) => ({
        ...oldLat,
        origin: instrutorAddress,
        destination: studentAddress,
      }));

      setTimeout(() => {
        setLocations((oldLat) => ({
          ...oldLat,
          origin: studentAddress,
          destination: studentAddress,
        }));
      }, 10000);
    };
    getLocations();
  }, []);

  useEffect(() => {
    if (currentPosition) {
      socket.emit("live-location", {
        eventId: params.eventId,
        liveLocation: currentPosition,
      });
    }
  }, [currentPosition]);

  const moveAlongPath = () => {
    const path = wayPointByRoad();
    setCurrentRoute([path[0]]);
    animateMarker(path);
  };

  const animateMarker = (path) => {
    let currentIndex = 0;
    setDirectionsRes(null);
    const interval = setInterval(() => {
      setCurrentPosition(path[currentIndex]);
      setCurrentRoute(path.slice(0, currentIndex + 1));
      let updateCenter = path[currentIndex];
      setMapCenter({ lat: updateCenter.lat, lng: updateCenter.lng });

      if (currentIndex < path.length - 1) {
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 1000);
  };

  const handleMapDirections = async () => {
    const directionsService = new google.maps.DirectionsService();
    const resulte = await directionsService.route({
      origin: locations.origin.latlng,
      destination: locations.destination.latlng,
      travelMode: google.maps.TravelMode.DRIVING,
    });

    setDirectionsRes(resulte);
    setDistance(resulte.routes[0].legs[0].distance.text);
    setDuration(resulte.routes[0].legs[0].duration.text);
  };

  const handleCamera = () => {
    setOpenCamera(true);
    setOpenPopup(true);
  };

  const handleModalYes = async () => {
    try {
      setOpenPopup(false);
      setOpenOTPModal(true);
    } catch (error) {
      alert(error.message);
    }
  };
  const handleModalNo = () => {
    navigate("/lessons");
    setStreams();
    setDistance("");
    setDuration("");
    setOpenPopup(false);
    setOpenCamera(false);
    setDirectionsRes(null);
    setMapCenter(defaultCenter);
    setMapZoom(defaultZoom);
    setOpenOTPModal(false);
  };
  const handleStop = () => {
    if (streams) {
      streams.getTracks().forEach((track) => track.stop());
      videoRef.current = null;
      socket.emit("recording-stop", params.eventId);
    }
    if (currentRoute) {
      socket.emit("location-stop", {
        eventId: params.eventId,
        liveLocation: currentRoute,
      });
    }
    setOpenPopup(false);
    setStreams();
    setOpenCamera(false);
    setClassSummaryDialog(true);
  };

  const handleStramming = async (stream) => {
    const sender = new MediaRecorder(stream);
    sender.ondataavailable = (event) => {
      if (event.data && event.data.size > 0) {
        if (!socket.connected) {
          socket.connect();
        }
        socket.emit("recording-start", {
          stream: event.data,
          eventId: params.eventId,
          liveLocation: currentPosition,
        });
      }
    };
    sender.start(1000);
  };

  const handleStudentVerifing = async (success) => {
    if (success) {
      try {
        await setStreams(videoRef.current.stream);
        handleStramming(videoRef.current.stream);
      } catch (error) {
        alert(error.message);
      }
      setOpenOTPModal(false);
      moveAlongPath();
      setIsTrainingStart(true);
      setDirectionsRes(null);
      // const address_one = await getCurrentLocation("student");
      // const address_two = await getCurrentLocation("Ontario L3Y 4W1.Canada");
      // setLocations({ origin: address_one, destination: address_two });
    }
  };

  const handleDeliverClass = () => {
    setClassSummaryDialog(false);
    navigate("/lessons");
  };

  const handleLoadMap = (map) => {
    setMap(map);
  };

  return (
    <div className="relative h-[750px] w-full bg-violet-100">
      {!mapObj.isLoaded ? (
        <p>Map is loading...</p>
      ) : (
        <CustomeGoogleMap
          center={mapCenter}
          directionsRes={directionsRes}
          onLoadMap={handleLoadMap}
          currentPosition={currentPosition}
          currentRoute={currentRoute}
          isTrainingStart={isTrainingStart}
        />
      )}
      {/* <div className="absolute top-0 flex w-full flex-col gap-1"> */}
      <LiveStatus
        streams={streams}
        onClick={handleCamera}
        onStop={handleStop}
      />
      {directionsRes && <InfoSummer distance={distance} duration={duration} />}
      {/* </div> */}
      {openCamera && (
        <div className="absolute bottom-[0px] right-5 z-50 h-52 w-52 rounded-md">
          <Webcam ref={videoRef} className="h-full w-full rounded-md" />
        </div>
      )}
      {openPopup && (
        <PickUpModal
          open={openPopup}
          onModalYes={handleModalYes}
          onModalNo={handleModalNo}
          streams={streams}
        />
      )}
      {openOTPModal && (
        <OTPModal
          open={openOTPModal}
          handleClose={() => handleModalNo()}
          onStdudentVerifing={handleStudentVerifing}
        />
      )}
      {classSummaryDialog && (
        <ClassSummaryDialog
          open={classSummaryDialog}
          handleClose={() => setClassSummaryDialog(false)}
          onDelivered={handleDeliverClass}
        />
      )}
    </div>
  );
};

export default StartClass;

const LiveStatus = ({ streams, onClick, onStop }) => {
  return (
    <div className="absolute top-0 flex h-5 w-full items-center justify-center gap-4 bg-[#e5e5e7] px-20 py-5">
      {streams ? (
        <div className="inline-flex items-center justify-center">
          <LiveTvIcon className="text-error" fontSize="medium" />
          <p className="text-3xs text-error">This class is being recorded!</p>
          <Button className="self-end text-error" onClick={onStop}>
            Stop
          </Button>
        </div>
      ) : null}
      {/* (
        <Button
          className="bg-bgPurpal px-5"
          variant="outlined"
          onClick={onClick}
        >
          Start Class
        </Button>
      ) */}
    </div>
  );
};

// useEffect(() => {
//   if (isTrainingStart) {
//     setTimeout(() => {
//       const lastLocation = locations.destination;
//       setLocations({ origin: lastLocation, destination: lastLocation });
//       setTimeout(() => {
//         setClassSummaryDialog(true);
//       }, 3000);
//     }, 10000);
//   }
// }, [isTrainingStart]);

// useEffect(() => {
//   const successCallback = async (position) => {
//     const latlng = {
//       lat: position.coords.latitude,
//       lng: position.coords.longitude,
//     };
//     const addressIntructor = await fetchLocationName(latlng);
//     const addressStduent = await fetchLocationName(studLatLng);
//     setIntructorLocation({ address: addressIntructor, latlng });
//     setLocations({
//       origin: addressIntructor,
//       destination: addressStduent,
//     });
//   };

//   const errorCallback = (error) => {
//     alert(error.message);
//   };

//   navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
// }, []);
