import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  schedule: [],
  loading: true,
  error: false,
};

export const ScheduleReducer = createReducer(initialState, (builder) => {
  builder.addCase("GetStudentScheduleRequest", (state) => {
    state.loading = true;
  });

  builder.addCase("GetStudentScheduleSuccess", (state, action) => {
    state.loading = false;
    state.error = false;
    state.schedule = action.payload;
  });

  builder.addCase("GetStudentScheduleFailure", (state, action) => {
    state.loading = false;
    state.error = action.payload;
  });

  builder.addCase("GetTeacherScheduleRequest", (state) => {
    state.loading = true;
  });

  builder.addCase("GetTeacherScheduleSuccess", (state, action) => {
    state.loading = false;
    state.error = false;

    state.schedule = action.payload;
  });

  builder.addCase("GetTeacherScheduleFailure", (state, action) => {
    state.loading = false;
    state.error = action.payload;
  });

  builder.addCase("UpdateScheduleRequest", (state) => {
    state.loading = true;
  });

  builder.addCase("UpdateScheduleSuccess", (state, action) => {
    state.loading = false;
    state.error = false;
    state.schedule = action.payload;
  });

  builder.addCase("UpdateScheduleFailure", (state, action) => {
    state.loading = false;
    state.error = action.payload;
  });

  builder.addCase("SendClassMissedEmailRequest", (state) => {
    state.loading = true;
  });
  
  builder.addCase("SendClassMissedEmailSuccess", (state, action) => {
    state.loading = false;
    state.error = false;
    state.schedule = action.payload;
  });
  
  builder.addCase("SendClassMissedEmailFailure", (state, action) => {
    state.loading = false;
    state.error = action.payload;
  });
});



