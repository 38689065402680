import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useEffect, useState } from 'react';

const PickUpModal = (props) => {
    const {onModalYes,onModalNo,open,streams}=props;
    const [btnDisabled,setBtnDisabled]=useState(false)

    useEffect(()=>{
      setBtnDisabled(Boolean(streams));
      console.log(streams);
    },[streams]);

  return (
    <Dialog open={open}>
        <DialogTitle className='text-center'>Pickup Student</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className='text-center px-5'>
          Did the client got seated in the vehicle?
          </DialogContentText>
        </DialogContent>
        
           
            <DialogActions>
                <Box className='flex justify-center items-center gap-4'>
                <Button variant='outlined' className='bg-primary px-5 py-1' onClick={onModalNo}>No</Button>
                <Button variant='contained' className='bg-primary px-5 py-1' onClick={onModalYes} disabled={btnDisabled}>Yes</Button>
                </Box>
                </DialogActions>
        
    </Dialog>

  )
}

export default PickUpModal