import React, { useState } from "react";
import ContactAndOtherInfo from "./Components/ContactAndOtherInfo";
import FreeHours from "./Components/FreeHours";
import { useSelector } from "react-redux";
import UserLocationInfo from "./Components/UserLocationInfo";
import ImagePreviewer from "../../../Components/ImagePreviewer/ImagePreviewer";
import { useAuthContext } from "../../../context/authContext";
import { Input } from "../../../Components/InputComps/InputComps";
import Schedule from "./Components/schedule/Schedule";
import ImagePreviewerEnrollmentForm from "../../../Components/ImagePreviewer/ImagePreviewerEnrollmentForm";
import LatestLicenseImage from "./Components/LatestLicenseImage";
import NewUserLocationInfo from "./Components/NewUserlocationInfo";
import { Country, State } from "country-state-city";
import axios from "axios";


const UserInfo = ({
  EnrollmentData,
  setEnrollmentData,
  PackageRef,
  Err,
  setShowTimeSlots,
  setErr,
  setAddresses,
  Addresses,
  StudentImage,
  setStudentImage,
  ShowSchedule,
  LatestLicense,
   setLatestLicense
}) => {
  const { UserInfo: UserInformation } = useSelector(
    (Store) => Store.LoginSignupReducer
  );
  const AddParentStyle = (e) =>
    (e.target.parentNode.style = "border:2px solid black");
  const RemoveParentStyle = (e) => (e.target.parentNode.style = "");
  let InputTypeTextStyle =
    "w-full text-6xs  md:text-5xs lg:text-2xs xl:text-3xs px-5 py-2 rounded-[8px] border-[#E8E8E8] border-solid border-[2px]";
  let HeadingStyle =
    "text-4xs sm:text-3xs md:text-2xs lg:text-xs xl:text-base text-[#404040] font-normal";
  let DivStyle =
    "w-full sm:w-[45%] flex flex-col justify-center items-start gap-4 max-w-[575px] ";

  let ExceptionSpanStyle =
    "flex w-full rounded-[8px] bg-white px-5 border-[#E8E8E8] border-solid border-[2px]";
  let ExceptionInputStyle =
    "w-full text-6xs md:text-5xs lg:text-2xs xl:text-3xs py-2 rounded-[8px] border-none focus:border-none focus:outline-none";
  let DivResponsive = "sm:w-[95%] md:w-11/12 lg:w-4/5 xl:w-[70%]";

  let Styling = {
    InputTypeTextStyle,
    HeadingStyle,
    DivStyle,
    ExceptionSpanStyle,
    ExceptionInputStyle,
    DivResponsive,
    AddParentStyle,
    RemoveParentStyle,
  };

 /*  const OnChange = (e, Key) => {
    // console.log("Key" + Key + "==", e.target.checked);
    let Data = e?.target?.value || e.target.checked;
    // console.log("!e.targe.cehck", !e.target.checked);
    let formattedData = Data;

    if (e.target.type === "checkbox") {
      formattedData = e.target.checked;
    }
    if (Key === "PostalCode") {
      // Remove all non-alphanumeric characters from the input
      const cleaned = Data.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();

      // Ensure the length of the cleaned postal code is at most 6 alphanumeric characters
      let postalCode = cleaned.substring(0, 6);

      // Format the postal code as per the Canadian format: A1A-A1A
      postalCode = postalCode.replace(
        /^([A-Za-z]\d{3})([A-Za-z]\d{3})/,
        "$1-$2"
      );

      formattedData = postalCode;
    }

    if (Key === "PhoneNumber") {
      // Remove all non-numeric characters from the input
      const cleaned = Data.replace(/\D/g, "");

      // Check if the cleaned data consists only of the country code "1"
      if (cleaned === "1") {
        formattedData = ""; // If yes, set formattedData to an empty string
      } else {
        // Check if the phone number starts with +1-
        if (cleaned.startsWith("1")) {
          // If it starts with +1-, remove the prefix and format the remaining digits as XXX-XXX-XXXX
          const PhoneNumber = cleaned
            .substring(1)
            .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
          formattedData = "+1-" + PhoneNumber;
          formattedData = formattedData.substring(0, 15);
        } else {
          // If it doesn't start with +1-, format the phone number as +1-XXX-XXX-XXXX
          const formattedPhoneNumber = "+1-" + cleaned;
          formattedData = formattedPhoneNumber.substring(0, 14);
        }
      }
    }

    //Date of birth verification
    console.log("Dob", e.target.value);
    if (Key === "DOB") {
      const dobString = e.target.value;
      const dobDate = new Date(dobString);

      const year = dobDate.getFullYear();
      const month = dobDate.getMonth() + 1; // Months are zero-based, so add 1
      const day = dobDate.getDate();

      // Calculate the age based on the entered DOB
      const today = new Date();
      const birthDate = new Date(Data);

      // Calculate age in years
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      const dayDiff = today.getDate() - birthDate.getDate();

      if (age < 16) {
        // Display error message for age less than 16
        setErr({ ...Err, [Key]: "Please enter valid Date of Birth!" });
      } else {
        // Clear error message if age is 16 or above
        delete Err?.[Key];
        setErr({ ...Err });
      }
    }

    setEnrollmentData({
      ...EnrollmentData,
      StudentData: {
        ...EnrollmentData?.StudentData,
        [Key]: formattedData,
      },
    });
    if (Key !== "DOB") {
      if (formattedData !== "" || formattedData !== true) {
        // console.log("Key", Key);
        delete Err?.[Key];
        setErr({ ...Err });
      } else {
        setErr({ ...Err, [Key]: `${Key} * is required` });
      }
    }
  }; */

  const [countryCode, setCountryCode] = useState("ca");
  const [locationData, setLocationData] = useState(null);
  const [newCountryPostalCode, setnewCountryPostalCode] = useState("");
  const [newStatePostalCode, setnewStatePostalCode] = useState("");
  const [newCityPostalCode, setnewCityPostalCode] = useState("ca");


  const OnChange = async (e, Key) => {
    // changed it to resolve the issue of last digit not deleting
    let Data;
    // console.log("!e.targe.cehck", !e.target.checked);
    

  // Handle checkbox input separately
  if (e.target.type === "checkbox") {
    Data = e.target.checked;
  } else {
    Data = e?.target?.value || "";  // Default to an empty string if the value is undefined
  }
    
   let formattedData = Data;
    let updatedEnrollmentData = { ...EnrollmentData };

    if (Key === "Country") {
      // Find the selected country based on the name or ISO code
      const selectedCountry = Country.getAllCountries().find(
        (country) => country.name === Data
      );
  
      if (selectedCountry) {
        // Set the country code (ISO code)
        const countryCodeISO = selectedCountry.isoCode.toLowerCase();
        setCountryCode(countryCodeISO); // This function should update the state or variable that holds the country code
    
        // Set formatted data as the selected country name
        formattedData = selectedCountry.name;
        console.log("formatted data in country on change is", formattedData)
        updatedEnrollmentData.StudentData.Country = formattedData;
      }
    }

    if (Key === "PostalCode") {
      // Remove all non-alphanumeric characters from the input
      const cleaned = Data.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();

      // Ensure the length of the cleaned postal code is at most 6 alphanumeric characters
      let postalCode = cleaned.substring(0, 6);

      // Format the postal code as per the Canadian format: A1A-A1A
      postalCode = postalCode.replace(
        /^([A-Za-z]\d{3})([A-Za-z]\d{3})/,
        "$1-$2"
      );

    
   //using google map api to fetch the country from the postal code 
         const fetchLocationData = async () => {
          try {
            console.log("data in postal code is:",postalCode)
            const response = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json`,
              {
                params: {
                  address: postalCode,
                  key: 'AIzaSyCWiF-rhJC5vG-_6akKZbSiJ1AIRxJe_mI', // Replace with your Google Maps API key
                },
              }
            );
            if (response.data.results.length > 0) {
              const location = response.data.results[0].address_components;
              console.log("response of google map postal api is:", response.data.results)
              console.log("response of google map postal api in terms of location is:", location)

              const countryPostal = location.find((comp) =>
                  comp.types.includes('country')
                )?.long_name || '';
                setnewCountryPostalCode(countryPostal)

                const state = location.find((comp) =>
                  comp.types.includes('administrative_area_level_1')
                )?.long_name || '';
                setnewStatePostalCode(state)

                const city = location.find((comp) =>
                  comp.types.includes('locality')
                )?.long_name || '';
                setnewCityPostalCode(city)

                const selectedPostalCountry = Country.getAllCountries().find(
                  (country) => country.name === countryPostal
                );
                const countryPostlaCodeISO = selectedPostalCountry.isoCode.toLowerCase();
                setCountryCode(countryPostlaCodeISO);

              console.log("response of country postal api in terms of location is:", countryPostal)
              console.log("response of state postal api in terms of location is:", state)
              console.log("response of city postal api in terms of location is:", city)
              setLocationData({
                country: countryPostal,
                state: state,
                city: city,
              });

              // Update the accumulated data object with fetched values
              updatedEnrollmentData.StudentData.Country = countryPostal;
              updatedEnrollmentData.StudentData.Province = state;
              updatedEnrollmentData.StudentData.City = city;
            }
          } catch (error) {
            console.error('Error fetching location data:', error);
          }
        }; 

       await fetchLocationData();

        console.log("location data in postal code is:", locationData)
        console.log("updatedenrollmentdata:", updatedEnrollmentData)

      formattedData = postalCode; 
      updatedEnrollmentData.StudentData.PostalCode = formattedData;
    }

    if (Key === "PhoneNumber") {
      // Remove all non-numeric characters from the input
     /*  const cleaned = Data.replace(/\D/g, "");

      // Check if the cleaned data consists only of the country code "1"
      if (cleaned === "0") {
        formattedData = ""; // If yes, set formattedData to an empty string
      } else {
        // Check if the phone number starts with +1-
        if (cleaned.startsWith("1")) {
          // If it starts with +1-, remove the prefix and format the remaining digits as XXX-XXX-XXXX
          const PhoneNumber = cleaned
            .substring(1)
            .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
          formattedData = "+1-" + PhoneNumber;
          formattedData = formattedData.substring(0, 15);
        } else {
          // If it doesn't start with +1-, format the phone number as +1-XXX-XXX-XXXX
         //  const formattedPhoneNumber = "+1-" + cleaned; 
         const formattedPhoneNumber = cleaned;
          formattedData = formattedPhoneNumber.substring(0, 12);
        } */
        formattedData = Data;
        console.log("formatted data in phone number on change is", formattedData)
        updatedEnrollmentData.StudentData.PhoneNumber = formattedData;
      }

      if (Key === "Gender") {
          formattedData = Data;
          console.log("formatted data in phone number on change is", formattedData)
          updatedEnrollmentData.StudentData.Gender = formattedData;
        }
  

    //Date of birth verification
    console.log("Dob", e.target.value);
    if (Key === "DOB") {
      const dobString = e.target.value;
      const dobDate = new Date(dobString);

      const year = dobDate.getFullYear();
      const month = dobDate.getMonth() + 1; // Months are zero-based, so add 1
      const day = dobDate.getDate();

      // Calculate the age based on the entered DOB
      const today = new Date();
      const birthDate = new Date(Data);

      // Calculate age in years
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      const dayDiff = today.getDate() - birthDate.getDate();

      if (age < 16) {
        // Display error message for age less than 16
        setErr({ ...Err, [Key]: "Please enter valid Date of Birth!" });
      } else {
        // Clear error message if age is 16 or above
        delete Err?.[Key];
        setErr({ ...Err });
      }
      updatedEnrollmentData.StudentData.DOB = dobString;

    }

    if (e.target.type === "checkbox") {
      setEnrollmentData({
          ...EnrollmentData,
          StudentData: {
            ...EnrollmentData?.StudentData,
            [Key]: formattedData,
          },
        });
    } else {
    setEnrollmentData(updatedEnrollmentData);
    console.log("enrollmentdata at the end on onchange is:",updatedEnrollmentData)
    console.log("enrollmentdata at the end of for real onchange is:",EnrollmentData)
    }
    // setEnrollmentData({
    //   ...EnrollmentData,
    //   StudentData: {
    //     ...EnrollmentData?.StudentData,
    //     [Key]: formattedData,
    //   },
    // });
    if (Key !== "DOB") {
      if (formattedData !== "" || formattedData !== true) {
        // console.log("Key", Key);
        delete Err?.[Key];
        setErr({ ...Err });
      } else {
        setErr({ ...Err, [Key]: `${Key} * is required` });
      }
    }
  };


  const onChangee = (e) => {
    if (e.target.files.length > 0) {
      delete Err?.StudentImage;
      setErr(Err);
      const selectedImage = e.target.files[0];
      setStudentImage(selectedImage);
    } else {
      setErr({ ...Err, StudentImage: "Profile Image is required" });
    }
  };

  // console.log("Imageeeeeeeeeeeee", Image);

  // console.log("billlll", Err);

  return (
    <div className=" mb-6 flex flex-col items-center justify-center gap-10 px-7">
      <div className="flex w-full items-center justify-start px-7"></div>
      <div
        className={`flex w-full flex-col flex-wrap items-start justify-between gap-10 sm:flex-row ${DivResponsive}`}
        id="StudentData"
      >
        <div className="w-full flex flex-col sm:flex-row justify-between gap-[4] sm:gap-10">
          <div className="student-PreviewContainer-Container">
            <h3 className="text-5xs font-normal md:text-2xs lg:text-xs xl:text-xs 2xl:w-[175px] 2xl:text-sm">
              Profile Picture
            </h3>
            <ImagePreviewerEnrollmentForm
              UserInformation={UserInformation}
              Id="studentProfile"
              PreviewStyle="Instructor_ImagePreview"
              BrowseBtnStyle="Instructor_BrowseBtn"
              ContainerStyle="Instructor_PreviewContainer"
              OnChange={onChangee}
              ImgUrl={StudentImage}
              type={"student"}
              readOnly={UserInformation?.StudentImage ? true : false}
            />
            {console.log(
              "EnrollmentData.StudentData.StudentImage",
              EnrollmentData.StudentData.StudentImage
            )}
            <p className="h-[14px] text-[14px] font-normal text-[red]">
              {Err?.StudentImage}
            </p>
          </div>
          <div className="w-full sm:w-[45%] 5xl:w-[575px] mt-[30px]">
              <UserName
              UserInformation={UserInformation}
              EnrollmentData={EnrollmentData}
              Styling={Styling}
              Err={Err}
              OnChange={OnChange}
            />
          </div>
        </div>

        <NewUserLocationInfo
          UserInformation={UserInformation}
          Addresses={Addresses}
          setAddresses={setAddresses}
          EnrollmentData={EnrollmentData}
          Styling={Styling}
          Err={Err}
          setErr={setErr}
          OnChange={OnChange}
          newCountryPostalCode={newCountryPostalCode}
          newStatePostalCode={newStatePostalCode}
          newCityPostalCode={newCityPostalCode}
        />

       {/*  <UserLocationInfo
          UserInformation={UserInformation}
          Addresses={Addresses}
          setAddresses={setAddresses}
          EnrollmentData={EnrollmentData}
          Styling={Styling}
          Err={Err}
          setErr={setErr}
          OnChange={OnChange}
        /> */}

        <ContactAndOtherInfo
          UserInformation={UserInformation}
          EnrollmentData={EnrollmentData}
          Err={Err}
          setShowTimeSlots={setShowTimeSlots}
          OnChange={OnChange}
          Styling={Styling}
          countryCode={countryCode}
        />

        {/* <FreeHours
          EnrollmentData={EnrollmentData}
          setEnrollmentData={setEnrollmentData}
          Styling={Styling}
          Err={Err}
        /> */}
        <div className="w-[45%] 5xl:w-[575px]">
        <LatestLicenseImage
        LatestLicense={LatestLicense}
         setLatestLicense={setLatestLicense}
         Err={Err}
         setErr={setErr}
         UserInformation={UserInformation}
        />
        </div>
      </div>
      <div className="w-full flex sm:w-[95%] md:w-11/12 lg:w-4/5 xl:w-[70%] gap-8">
        <div className="flex w-full gap-8 sm:w-[45%] flex-col">
          {
           ShowSchedule ? 
          <Schedule EnrollmentData={EnrollmentData} setShowTimeSlots={setShowTimeSlots} /> : null
          }

          <div className="w-full ">
            <button
              type="button"
              id="Schedule"
              className="cursor-pointer rounded-xl border-none bg-[#A1A3EF] px-5 py-2 text-xs text-white"
              onClick={() => setShowTimeSlots(true)}
            >
             {ShowSchedule ? 'Update Schedule' : 'Create Schedule'}
            </button>
          </div>
        </div>
      </div>

      <div
        className={`flex w-full flex-col items-center justify-center gap-11 ${DivResponsive}`}
      >
        <div className="flex w-full flex-col gap-1">
          <p className="text-4xs text-[#0080FF] sm:text-xs">
            *Please note all information on the registration form will be kept
            confidential and will be disclosed only to VLI
          </p>
          <h4 className="text-left  text-4xs font-normal text-[red]">
            {Err?.TermsAndConditions ? Err?.TermsAndConditions : null}
          </h4>
          <span className="flex items-start gap-2 sm:items-center sm:gap-4">
            <input
              className="h-6 w-6"
              type="checkbox"
              // name=""
              id="AgreeTerms"
              checked={EnrollmentData?.StudentData?.AgreeTermsAndConditions}
              onChange={(e) => OnChange(e, "TermsAndConditions")}
            />
            <p className="texet-4xs sm:text-3xs lg:text-2xs xl:text-xs">
              By clicking this box, you agree to the terms, conditions and
              policies
            </p>
          </span>
        </div>
        <button
          type="button"
          className="w-fit cursor-pointer rounded-2xl border-none bg-[#A1A3EF] px-6 py-[10px] text-4xs text-white sm:text-3xs md:text-2xs lg:text-xs xl:text-sm 2xl:text-base"
          onClick={(e) => PackageRef.current?.scrollIntoView()}
        >
          Select Package
        </button>
      </div>
    </div>
  );
};

export default UserInfo;

function UserName({ Styling, EnrollmentData, Err, OnChange, UserInformation }) {
  const { HeadingStyle, InputTypeTextStyle, DivStyle } = Styling;
  return (
    <div className="flex flex-col gap-[35px]">
      <div>
        {/* <label htmlFor="FirstName" className={HeadingStyle}>
                    First Name
                </label> */}
        {/* <h4 className="font-normal text-[red]">
                    {" "}
                    {Err?.FirstName ? Err?.FirstName : null}
                </h4> */}
        <Input
          Label="First name *"
          Placeholder="John"
          Id="FirstName"
          Err={Err?.FirstName}
          State={EnrollmentData?.StudentData?.FirstName}
          readOnly={UserInformation?.FirstName ? true : false}
          onChange={(e) => OnChange(e, "FirstName")}
        />
        {/* <input
                    className={InputTypeTextStyle}
                    type="text"
                    placeholder="John"
                    id="FirstName"
                    required
                    value={EnrollmentData?.StudentData?.FirstName}
                    onChange={(e) => OnChange(e, "FirstName")}
                /> */}
      </div>
      <div>
        {/* <label htmlFor="LastName" className={HeadingStyle}>
                    Last Name
                </label>
                <h4 className="font-normal text-[red]">
                    {" "}
                    {Err?.LastName ? Err?.LastName : null}
                </h4> */}
        <Input
          Label="Last name *"
          Placeholder="Smith"
          Id="LastName"
          readOnly={UserInformation?.LastName ? true : false}
          Err={Err?.LastName}
          State={EnrollmentData?.StudentData?.LastName}
          onChange={(e) => OnChange(e, "LastName")}
        />
        {/* <input
                    className={InputTypeTextStyle}
                    type="text"
                    placeholder="Smith"
                    id="LastName"
                    required
                    value={EnrollmentData?.StudentData?.LastName}
                    onChange={(e) => OnChange(e, "LastName")}
                /> */}
      </div>
    </div>
  );
}
