import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { InstructorRowTemplate } from "../../../../Components/InstructorTemplate/InstructorTemplate";
import { BaseUrl } from "../../../../Actions/Base";

function StudentTable({ Students, filtered }) {
    const Navigate = useNavigate();

    const handleManageClick = (EnrollmentId, UserId) => {
        const targetRoute = filtered
          ? `/enrolledcourse/${EnrollmentId}`
          : `/enrolledcourse/manage/${EnrollmentId}`;
    
        // Navigate to the target route and pass UserId as state
        Navigate(targetRoute, { state: { userId: UserId } });
      };

    console.log("Students==>", Students)
    
    return (
        <>
            <table
                className="student_table addCourse4Table "
                style={{ background: "rgba(0,0,0,0)" }}
            >
                <thead>
                    <tr>
                        <th className="w-[3%] text-center">Profile</th>
                        <th className="w-[9%] text-center">ID</th>
                        <th className="w-[10%] text-center">Name</th>
                        <th className="w-[16.5%] text-center">Email</th>
                        <th className="w-[14%] text-center">Status</th>
                        <th className="w-[16%] text-center">Phone</th>
                        <th colSpan={2} className="w-[3.5%]  text-center">Actions</th>
                       
                    </tr>
                </thead>
                <tbody>
                    {Students &&
                        Students?.map((value, index) => {
                            const { CoursePackage, EnrollmentId } =
                                value?.CourseEnrollment;
                            const { PaymentStatus, Status } = value;

                            const PaymentOrClientsColor =
                                !PaymentStatus === 0
                                    ? "text-[green] bg-[#aaffbe]"
                                    : "text-white bg-[#b5aaff]";
                            const StatusColor = !Status
                                ? "text-[#0D472D] bg-[#F7E5D1]"
                                : "text-white bg-[#b5aaff]";
                            const PaymentMethod =
                                "Installments | " +
                                CoursePackage.InstallmentSchedule +
                                " | " +
                                CoursePackage.Installments;
                            const OnClick = () => {
                                Navigate(`/enrolledcourse/${EnrollmentId}`);
                            };
                            const Delete = () => (
                                <Link
                                    onClick={(e) => e.stopPropagation()}
                                    to={`/enrolledcourse/${EnrollmentId}`}
                                >
                                    <button
                                        type="button"
                                        className="absolute  left-0 w-[80px] 2sm:w-auto  cursor-pointer rounded md:rounded-[10px] border-0 bg-[#4C34AD]  px-4 py-1 text-[13px] md:text-[15px] font-medium text-white md:mb-[2px] md:px-8 md:py-2 lg:px-6 lg:py-2 "
                                        >
                                        Delete
                                    </button>
                                </Link>
                            );

                           {/*  const Manage = () => (
                                    <button
                                        type="button"
                                        className="absolute  left-0 w-[80px] 2sm:w-auto cursor-pointer  rounded md:rounded-[10px] border-0 bg-[#4C34AD]  px-4 py-1 text-[13px] md:text-[15px] font-medium text-white md:mb-[2px] md:px-8 md:py-2 lg:px-9 lg:py-2 "
                                        onClick={() => handleManageClick(EnrollmentId, Students.UserId)}
                                        >
                                        Manage
                                    </button>
                            ); */}

                             const Manage = () => (
                                <Link
                                    onClick={(e) => e.stopPropagation()}
                                    to={
                                        filtered
                                            ? `/enrolledcourse/${EnrollmentId}`
                                            : `/enrolledcourse/manage/${EnrollmentId}`
                                    }
                                    state={{ userId: value.UserId }}
                                >
                                    <button
                                        type="button"
                                        className="absolute  left-0 w-[80px] 2sm:w-auto cursor-pointer  rounded md:rounded-[10px] border-0 bg-[#4C34AD]  px-4 py-1 text-[13px] md:text-[15px] font-medium text-white md:mb-[2px] md:px-8 md:py-2 lg:px-9 lg:py-2 "
                                        >
                                        Manage
                                    </button>
                                </Link>
                            );
 
                            const StudentImage =  `${BaseUrl}/api/images/Student?url=${value?.StudentImage}`
                            return (
                                <InstructorRowTemplate
                                    type={"students"}
                                    OnClick={OnClick}
                                    TrStyle="cursor-pointer"
                                    key={value?.UserId}
                                    index={index}
                                    LastComp={Delete}
                                    LastCompp={Manage}
                                    FirstName={value?.FirstName}
                                    phone={value?.PhoneNumber}
                                    ImgUrl={StudentImage}
                                    Email={value.Email}
                                    StudentId={
                                        value?.CourseEnrollment?.IdCardNo
                                    }
                                    LastName={value?.LastName}
                                    Status={value?.CourseEnrollment?.Status}
                                    createdAt={
                                        value?.CourseEnrollment?.createdAt
                                    }
                                    PaymentOrUnderClients={
                                        !PaymentStatus ? PaymentMethod : "free"
                                    }
                                    StatusColor={StatusColor}
                                    PaymentOrClientsColor={
                                        PaymentOrClientsColor
                                    }
                                />
                            );
                        })}
                </tbody>
            </table>
        </>
    );
}

export default StudentTable;
